import React, { useMemo } from 'react';
import { useFragment, graphql } from 'react-relay';
import type { confluencePages_issueFieldConfluencePagesReadviewFull_ConfluencePagesReadView$key as ConfluencePagesFragment } from '@atlassian/jira-relay/src/__generated__/confluencePages_issueFieldConfluencePagesReadviewFull_ConfluencePagesReadView.graphql';
import { ItemWithEntryPointPopup } from '@atlassian/jira-item-with-popup/src/ui/item-with-entrypoint-popup/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { SimpleInlineSmartLink } from '@atlassian/jira-issue-field-smart-link/src/ui/simple-inline-smart-link/index.tsx';
import type { ConfluencePagesReadViewProps } from './types.tsx';
import { confluencePagesPopupContentEntryPoint } from './popup-content/entrypoint.tsx';
import messages from './messages.tsx';
import type { Props as PopupContentProps } from './popup-content/types.tsx';

/**
 * The ConfluencePagesReadView will show a read only view of ConfluencePages field.
 * @param props [ConfluencePagesReadViewProps](./types.tsx)
 */
export const ConfluencePagesReadView = ({ fragmentRef }: ConfluencePagesReadViewProps) => {
	const { formatMessage } = useIntl();
	const { confluenceRemoteIssueLinks } = useFragment<ConfluencePagesFragment>(
		graphql`
			fragment confluencePages_issueFieldConfluencePagesReadviewFull_ConfluencePagesReadView on JiraConfluenceRemoteIssueLinksField {
				confluenceRemoteIssueLinks(first: 1000) @optIn(to: "JiraConfluenceRemoteIssueLinksField") {
					# eslint-disable-next-line @atlassian/relay/must-colocate-fragment-spreads
					...popupContent_issueFieldConfluencePagesReadviewFull
					totalCount
					edges {
						node {
							href
						}
					}
				}
			}
		`,
		fragmentRef,
	);

	const totalCount = confluenceRemoteIssueLinks?.totalCount ?? 0;
	const node = confluenceRemoteIssueLinks?.edges?.[0]?.node;
	const popupContentProps: PopupContentProps | null = useMemo(
		() =>
			confluenceRemoteIssueLinks
				? {
						fragmentRef: confluenceRemoteIssueLinks,
					}
				: null,
		[confluenceRemoteIssueLinks],
	);

	if (!popupContentProps || !node || totalCount === 0) {
		return null;
	}

	return (
		<ItemWithEntryPointPopup
			ItemComponent={<SimpleInlineSmartLink url={node.href ?? ''} />}
			totalItems={Math.min(totalCount, 1000)}
			entryPoint={confluencePagesPopupContentEntryPoint}
			entryPointParams={{}}
			entryPointRuntimeProps={popupContentProps}
			showMoreTooltip={formatMessage(messages.showMore)}
			actionSubjectIdPrefix="confluencePages"
		/>
	);
};
