/**
 * @generated SignedSource<<9c91a79ca0472abe266a7e6cd7908dc4>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type confluencePages_issueFieldConfluencePagesReadviewFull_ConfluencePagesReadView$data = {
  readonly confluenceRemoteIssueLinks: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly href: string | null | undefined;
      } | null | undefined;
    } | null | undefined> | null | undefined;
    readonly totalCount: number | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"popupContent_issueFieldConfluencePagesReadviewFull">;
  } | null | undefined;
  readonly " $fragmentType": "confluencePages_issueFieldConfluencePagesReadviewFull_ConfluencePagesReadView";
};
export type confluencePages_issueFieldConfluencePagesReadviewFull_ConfluencePagesReadView$key = {
  readonly " $data"?: confluencePages_issueFieldConfluencePagesReadviewFull_ConfluencePagesReadView$data;
  readonly " $fragmentSpreads": FragmentRefs<"confluencePages_issueFieldConfluencePagesReadviewFull_ConfluencePagesReadView">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "confluencePages_issueFieldConfluencePagesReadviewFull_ConfluencePagesReadView",
  "selections": [
    {
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 1000
        }
      ],
      "concreteType": "JiraConfluenceRemoteIssueLinkConnection",
      "kind": "LinkedField",
      "name": "confluenceRemoteIssueLinks",
      "plural": false,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "popupContent_issueFieldConfluencePagesReadviewFull"
        },
        {
          "kind": "ScalarField",
          "name": "totalCount"
        },
        {
          "concreteType": "JiraConfluenceRemoteIssueLinkEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraConfluenceRemoteIssueLink",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "href"
                }
              ]
            }
          ]
        }
      ],
      "storageKey": "confluenceRemoteIssueLinks(first:1000)"
    }
  ],
  "type": "JiraConfluenceRemoteIssueLinksField"
};

(node as any).hash = "9339f5dfa3bce053620f77695601921b";

export default node;
