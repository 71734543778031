import React, { useCallback } from 'react';
import { commitLocalUpdate } from 'react-relay';
import { token } from '@atlaskit/tokens';
import InfoIcon from '@atlaskit/icon/core/migration/information--info';
import getRelayEnvironment from '@atlassian/jira-relay-environment/src/index.tsx';
import type {
	JiraIssueExpandedGroup,
	JiraIssueExpandedParent,
} from '@atlassian/jira-relay/src/__generated__/realtimeIssueDetails_Query.graphql.ts';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import {
	useConnectionsList,
	type ConnectionDetails,
} from '@atlassian/jira-issue-table-hierarchy/src/controllers/connections-list/index.tsx';
import { useFlagsService } from '@atlassian/jira-flags';
import {
	useIsDensityFull,
	useIsInlineEditingEnabled,
} from '../../controllers/features/selectors.tsx';
import { useIssueFetchQueryVariables } from '../../controllers/issue-fetch-query-variables/index.tsx';
import { useIssueRealtimeUpdater } from '../../controllers/issue-realtime-updater/index.tsx';
import { useGetRealtimeIssueDetails } from '../../services/realtime-issue-details/index.tsx';
import type { SearchViewContextInputType } from '../../services/realtime-issue-details/types.tsx';
import { useGetGroupsForIssues } from '../../services/groups-for-issues/index.tsx';
import messages from './messages.tsx';

export const generateSearchViewContextInput = ({
	connections,
	isGroupingEnabled,
	groupedByFieldId,
}: {
	connections: Record<string, ConnectionDetails>;
	isGroupingEnabled: boolean;
	groupedByFieldId?: string | null;
}): SearchViewContextInputType => {
	const expandedParents: JiraIssueExpandedParent[] = [];
	const groups: JiraIssueExpandedGroup[] = [];

	Object.values(connections).forEach((connection) => {
		if (connection.type === 'PARENT_CHILDREN') {
			expandedParents.push({ parentIssueId: connection.parentId, first: connection.first });
		} else if (connection.type === 'GROUP_CHILDREN') {
			groups.push({
				jql: connection.jql,
				fieldValue: connection.fieldValue,
				first: connection.first,
			});
		}
	});

	if (isGroupingEnabled && groupedByFieldId) {
		return { expandedGroups: { groupedByFieldId, groups } };
	}

	return { expandedParents };
};

export const useRealtimeUpdatesForIssues = () => {
	const environment = getRelayEnvironment();
	const cloudId = useCloudId();
	const { fieldSetIds } = useIssueFetchQueryVariables();
	const isDensityFull = useIsDensityFull();
	const isInlineEditingEnabled = useIsInlineEditingEnabled();
	const { connections } = useConnectionsList();
	const getGroupsForIssues = useGetGroupsForIssues();

	const getRealtimeIssueDetails = useGetRealtimeIssueDetails();
	const { issuePositionUpdater, groupsForIssueUpdater } = useIssueRealtimeUpdater();

	return useCallback(
		async ({
			keys,
			jql,
			groupByFieldId,
			numGroupsLoaded,
			groupConnectionId,
			isHierarchyEnabled,
			projectKey,
			isStatusTrackingEnabled,
		}: {
			keys: string[];
			jql: string;
			groupByFieldId: string | undefined;
			groupConnectionId: string | undefined;
			numGroupsLoaded: number;
			isHierarchyEnabled: boolean;
			projectKey: string;
			isStatusTrackingEnabled: boolean;
		}) => {
			const isGroupingEnabled = !!groupByFieldId;
			const searchViewContextInput = generateSearchViewContextInput({
				connections,
				isGroupingEnabled,
				groupedByFieldId: groupByFieldId,
			});

			const issueDetails = await getRealtimeIssueDetails({
				keys,
				cloudId,
				fieldSetIds,
				isInlineEditingEnabled,
				isDensityFull,
				isHierarchyEnabled,
				isGroupingEnabled,
				issueSearchInput: {
					jql,
				},
				searchViewContextInput,
				projectKey,
				isStatusTrackingEnabled,
			});

			issueDetails?.jira?.issuesByKey?.filter(Boolean).forEach(async (issue) => {
				const searchViewContext = issue.searchViewContext;
				const id = issue.issueId;
				const issueRecordId = issue.__id;
				const parentIssueNodeRecordId = issue.parentIssueField?.parentIssue?.__id;

				const fieldSetConnectionId = issue?.fieldSetsById?.__id;
				if (id && issueRecordId) {
					commitLocalUpdate(environment, (store) => {
						issuePositionUpdater({
							store,
							searchViewContext,
							fieldSetConnectionId,
							issueId: id,
							issueRecordId,
							parentIssueNodeRecordId,
							groupConnectionId,
							projectKey,
						});
					});
				}
			});

			if (!isGroupingEnabled || !groupByFieldId) {
				return;
			}

			const issueKeysWithoutContext =
				issueDetails?.jira?.issuesByKey
					?.map((issue) =>
						!issue?.searchViewContext?.contexts?.length && !!issue?.key ? issue.key : null,
					)
					.filter(Boolean) || [];

			const groupsToInsert = await getGroupsForIssues({
				issueKeys: issueKeysWithoutContext,
				fieldId: groupByFieldId,
				jql,
				numGroupsLoaded,
			});

			if (groupsToInsert.length > 0) {
				commitLocalUpdate(environment, (store) => {
					groupsForIssueUpdater({
						store,
						groupConnectionId,
						groupsForIssue: groupsToInsert,
					});
				});
			}
		},
		[
			connections,
			cloudId,
			fieldSetIds,
			getRealtimeIssueDetails,
			isInlineEditingEnabled,
			isDensityFull,
			environment,
			issuePositionUpdater,
			getGroupsForIssues,
			groupsForIssueUpdater,
		],
	);
};

const REALTIME_UPDATE_EXCEEDED_MAX_FLAG_ID =
	'native-issue-table.ui.realtime-updater.flag.info.native-issue-table.ui.realtime-update-exceeded-max-flag';
export const useRealtimeUpdateExceededMaxFlag = () => {
	const { showFlag, hasFlag, dismissFlag } = useFlagsService();
	const { connections } = useConnectionsList();
	const environment = getRelayEnvironment();

	return useCallback(
		({ onRefresh }: { onRefresh: () => void }) => {
			if (!hasFlag?.(REALTIME_UPDATE_EXCEEDED_MAX_FLAG_ID)) {
				showFlag({
					type: 'info',
					icon: <InfoIcon spacing="spacious" label="" color={token('color.icon.information')} />,
					title: messages.realtimeUpdateExceededMaxHeader,
					description: messages.realtimeUpdateExceededMaxDescription,
					messageId: REALTIME_UPDATE_EXCEEDED_MAX_FLAG_ID,
					id: REALTIME_UPDATE_EXCEEDED_MAX_FLAG_ID,
					messageType: 'transactional',
					isAutoDismiss: true,
					actions: [
						{
							content: messages.realtimeUpdateExceededMaxRefreshButton,
							onClick: () => {
								// This will reload all expanded parents/groups
								environment.commitUpdate((store) => {
									Object.values(connections).forEach((connection) => {
										const connectionToInvalidate = store.get(connection.connectionId);
										if (connectionToInvalidate) {
											connectionToInvalidate.invalidateRecord();
										}
									});
								});

								dismissFlag(REALTIME_UPDATE_EXCEEDED_MAX_FLAG_ID);
								onRefresh();
							},
						},
					],
				});
			}
		},
		[connections, dismissFlag, environment, hasFlag, showFlag],
	);
};
