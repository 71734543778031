import { useCallback } from 'react';
import { useOrgId } from '@atlassian/jira-router-resources-navigation-org-id/src/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useIsStagingOrDevOrLocal } from '@atlassian/jira-tenant-context-controller/src/components/environment/index.tsx';
import { ATLASSIAN_HOME_URL, ATLASSIAN_HOME_URL_STAGING } from './constants.tsx';

export const useGoalUrlConstructor = () => {
	const cloudId = useCloudId();
	const { data: orgId } = useOrgId();
	const isStagingOrDevOrLocal = useIsStagingOrDevOrLocal();

	return useCallback(
		(goalKey: string) => {
			const atlassianHomeUrl = isStagingOrDevOrLocal
				? ATLASSIAN_HOME_URL_STAGING
				: ATLASSIAN_HOME_URL;

			return `${atlassianHomeUrl}/o/${orgId}/s/${cloudId}/goal/${goalKey}`;
		},
		[cloudId, isStagingOrDevOrLocal, orgId],
	);
};

export const getGoalKeyFromUrl = (url: string) => url.split('/').pop();
