import React, { useCallback, useMemo, useState } from 'react';
import { useFragment, graphql, useMutation } from 'react-relay';
import type { NullableOptions } from '@atlassian/jira-issue-selectable-field-edit-view/src/ui/types.tsx';
import type { OnSubmitCallbacks } from '@atlassian/jira-issue-field-inline-edit-actions/src/controllers/types.tsx';
import { useFieldInlineEditActions } from '@atlassian/jira-issue-field-inline-edit-actions/src/controllers/index.tsx';
import type { ValidationFieldProps } from '@atlassian/jira-issue-field-inline-edit-lite/src/ui/field-inline-edit-lite/types.tsx';
import { FieldInlineEditLiteWithEntryPoint } from '@atlassian/jira-issue-field-inline-edit-lite/src/ui/index.tsx';
import type { MultiSelectEditViewProps } from '@atlassian/jira-issue-field-multi-select-editview-full/src/ui/multi-select/types.tsx';
import MultiSelectEditViewEntryPoint from '@atlassian/jira-issue-field-multi-select-editview-full/src/entrypoint.tsx';
import { MultiSelectReadView } from '@atlassian/jira-issue-field-multi-select-readview-full/src/ui/multi-select/index.tsx';
import type { multiSelect_issueFieldMultiSelect_MultiSelectField_Mutation as MultiSelectMutation } from '@atlassian/jira-relay/src/__generated__/multiSelect_issueFieldMultiSelect_MultiSelectField_Mutation.graphql';
import type { multiSelect_issueFieldMultiSelectInlineEditFull_MultiSelectInlineEditView_fragmentRef$key as MultiSelectFragment } from '@atlassian/jira-relay/src/__generated__/multiSelect_issueFieldMultiSelectInlineEditFull_MultiSelectInlineEditView_fragmentRef.graphql';
import type { multiSelect_issueFieldMultiSelectInlineEditFull_MultiSelectInlineEditViewWithIsEditable_fragmentRef$key as MultiSelectWithIsEditableFragment } from '@atlassian/jira-relay/src/__generated__/multiSelect_issueFieldMultiSelectInlineEditFull_MultiSelectInlineEditViewWithIsEditable_fragmentRef.graphql';
import { useInlineEditFieldInjections } from '@atlassian/jira-issue-field-injections/src/controllers/inline-edit-injections-context/index.tsx';
import type {
	MultiSelectInlineEditViewProps,
	MultiSelectInlineEditViewWithIsEditableProps,
} from './types.tsx';

/** Determine if the updated edit view value is equal to our original value before submitting a mutation. */
const isEqualOption = (a: NullableOptions, b: NullableOptions) => {
	if (a === null || b === null) return a === b;
	if (a.length !== b.length) return false;

	const aIdSet = new Set(a.map((option) => option.id));
	return b.every((option) => aIdSet.has(option.id));
};

/**
 * Inline edit will handle the switching behaviour between the 'readView' and 'editView' components. This variant allows
 * consumers to define their own value to determine whether the field is editable.
 *
 * In most cases consumers should use `MultiSelectInlineEditView` which will enforce that the user has permission to
 * edit the field within the issue view. However, this component can be used for experiences that have differing
 * permissions or want finer control for how this data is retrieved, e.g. lazy loading editability.
 *
 * @param props [MultiSelectInlineEditViewWithIsEditableProps](./types.tsx)
 */
export const MultiSelectInlineEditViewWithIsEditable = ({
	attributes,
	editViewPopup,
	editViewPopupAlignBlock,
	fragmentRef,
	isEditable,
	isEditing: startWithEditViewOpen = false,
	isTruncated,
	menuPosition,
	menuPortalTarget,
	onSubmit,
	onSubmitFailed,
	onSubmitSucceeded,
	readViewFitContainerHeight,
	spacing = 'compact',
	isTruncatedWithHoverPopover,
}: MultiSelectInlineEditViewWithIsEditableProps) => {
	// #region Relay
	const data = useFragment<MultiSelectWithIsEditableFragment>(
		graphql`
			fragment multiSelect_issueFieldMultiSelectInlineEditFull_MultiSelectInlineEditViewWithIsEditable_fragmentRef on JiraMultipleSelectField {
				id
				name
				fieldId
				type
				...multiSelect_issueFieldMultiSelectReadviewFull_MultiSelectReadView
				selectedOptions {
					edges {
						node {
							id
							value
							optionId
						}
					}
				}
			}
		`,
		fragmentRef,
	);

	const [commit] = useMutation<MultiSelectMutation>(graphql`
		mutation multiSelect_issueFieldMultiSelect_MultiSelectField_Mutation(
			$input: JiraUpdateMultipleSelectFieldInput!
		) @raw_response_type {
			jira @optIn(to: ["JiraIssueFieldMutations"]) {
				updateMultipleSelectField(input: $input) {
					success
					errors {
						message
					}
					field {
						...multiSelect_issueFieldMultiSelectInlineEditFull_MultiSelectInlineEditViewWithIsEditable_fragmentRef
					}
				}
			}
		}
	`);
	// #endregion Relay

	// #region Common state
	const { id: uniqueFieldId, name, selectedOptions, fieldId, type: fieldType } = data;

	const initialValue: NullableOptions = useMemo(() => {
		if (!selectedOptions?.edges) {
			return null;
		}

		return selectedOptions.edges.map((edge) => ({
			id: edge?.node?.id ?? '',
			optionId: edge?.node?.optionId ?? '',
			value: edge?.node?.value ?? '',
			selectableLabel: edge?.node?.value ?? '',
			selectableGroupKey: null,
			selectableIconUrl: null,
		}));
	}, [selectedOptions?.edges]);

	const [updatedValue, setUpdatedValue] = useState<NullableOptions>(initialValue);

	const { overriding } = useInlineEditFieldInjections();
	const { overrideLabel, overrideIsEditable, overrideFieldOptionsFilter } = overriding;

	const fieldName = useMemo(() => overrideLabel(name), [overrideLabel, name]);

	const isFieldEditable = useMemo(
		() => overrideIsEditable(isEditable),
		[overrideIsEditable, isEditable],
	);

	const filterOptionsById = useMemo(
		() => overrideFieldOptionsFilter(null),
		[overrideFieldOptionsFilter],
	);
	// #endregion Common state

	// #region Actions
	const handleSubmit = useCallback(
		(values: NullableOptions, { onSuccess, onFail }: OnSubmitCallbacks) => {
			onSubmit?.(values);

			commit({
				variables: {
					input: {
						id: uniqueFieldId,
						operations: [
							{
								operation: 'SET',
								ids: values?.map((selection) => selection.id) ?? [],
							},
						],
					},
				},
				onCompleted(response) {
					if (response.jira?.updateMultipleSelectField?.success) {
						onSuccess();
					} else {
						onFail();
					}
				},
				onError(error: Error) {
					onFail(error);
				},
				optimisticResponse: {
					jira: {
						updateMultipleSelectField: {
							success: true,
							errors: null,
							field: {
								id: uniqueFieldId,
								name: fieldName,
								fieldId,
								type: fieldType,
								selectedOptions: {
									edges:
										values?.map((edge) => ({
											node: {
												id: edge.id ?? '',
												value: edge.selectableLabel ?? '',
												optionId: edge.optionId ?? '',
											},
										})) ?? [],
								},
							},
						},
					},
				},
			});
		},
		[commit, fieldId, fieldName, fieldType, onSubmit, uniqueFieldId],
	);

	const {
		invalidMessage,
		isEditing,
		hasServerValidationError,
		handleCancel,
		handleEdit,
		handleConfirm,
		handleChange,
		handleCopy,
		handleCut,
		handlePaste,
	} = useFieldInlineEditActions({
		attributes,
		fieldId,
		fieldName,
		fieldType,
		initialValue,
		isValueEqual: isEqualOption,
		onSubmit: handleSubmit,
		onSubmitFailed,
		onSubmitSucceeded,
		onUpdateValue: setUpdatedValue,
		startWithEditViewOpen,
		updatedValue,
		clipboardComponentType: 'multiSelect',
	});
	// #endregion Actions

	// #region Read view
	const renderReadView = useCallback(
		() => (
			<MultiSelectReadView
				fragmentRef={data}
				isTruncated={isTruncated}
				isTruncatedWithHoverPopover={isTruncatedWithHoverPopover}
			/>
		),
		[data, isTruncated, isTruncatedWithHoverPopover],
	);
	// #endregion

	// #region Edit view
	const getEditViewProps = (fieldProps: ValidationFieldProps): MultiSelectEditViewProps => ({
		...fieldProps,
		autoFocus: true,
		fieldId: uniqueFieldId,
		fieldName,
		value: updatedValue,
		menuPosition,
		menuPortalTarget,
		onChange: handleChange,
		filterOptionsById,
		openMenuOnFocus: true,
		spacing,
	});
	// #endregion Edit view

	return (
		<FieldInlineEditLiteWithEntryPoint
			editViewPopup={editViewPopup}
			editViewPopupAlignBlock={editViewPopupAlignBlock}
			editViewPopupMinWidth="small"
			editViewEntryPoint={MultiSelectEditViewEntryPoint}
			editViewEntryPointParams={{}}
			getEditViewProps={getEditViewProps}
			fieldName={fieldName}
			hasUnsubmittedChanges={hasServerValidationError}
			invalidMessage={invalidMessage}
			isEditing={isEditing}
			isEditable={isFieldEditable}
			onCancel={handleCancel}
			onConfirm={handleConfirm}
			onEdit={handleEdit}
			onCopy={handleCopy}
			onCut={handleCut}
			onPaste={handlePaste}
			readViewFitContainerHeight={readViewFitContainerHeight}
			readView={renderReadView}
			hideActionButtons
			hasReadViewTruncateDisabled={isTruncatedWithHoverPopover}
		/>
	);
};

/**
 * Inline edit will handle the switching behaviour between the 'readView' and 'editView' components.
 *
 * @param props [MultiSelectInlineEditViewProps](./types.tsx)
 */
export const MultiSelectInlineEditView = ({
	fragmentRef,
	...props
}: MultiSelectInlineEditViewProps) => {
	// #region Relay
	const data = useFragment<MultiSelectFragment>(
		graphql`
			fragment multiSelect_issueFieldMultiSelectInlineEditFull_MultiSelectInlineEditView_fragmentRef on JiraMultipleSelectField {
				...multiSelect_issueFieldMultiSelectInlineEditFull_MultiSelectInlineEditViewWithIsEditable_fragmentRef
				fieldConfig {
					isEditable
				}
			}
		`,
		fragmentRef,
	);
	// #endregion

	return (
		<MultiSelectInlineEditViewWithIsEditable
			{...props}
			fragmentRef={data}
			isEditable={data.fieldConfig?.isEditable ?? false}
		/>
	);
};
