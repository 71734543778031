import React, { useMemo } from 'react';
import { useFragment, graphql } from 'react-relay';
import { Inline, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { EmptyFieldText } from '@atlassian/jira-issue-empty-field-text/src/ui/index.tsx';
import type { multiSelect_issueFieldMultiSelectReadviewFull_MultiSelectReadView$key as MultiSelectReadViewFragment } from '@atlassian/jira-relay/src/__generated__/multiSelect_issueFieldMultiSelectReadviewFull_MultiSelectReadView.graphql';
import { OptionTag } from '@atlassian/jira-option-color-picker/src/option-tag/index.tsx';
import {
	TagItem,
	TagItemPopupContentWrapper,
} from '@atlassian/jira-list-with-popup/src/ui/tag-item/index.tsx';
import ListWithPopup from '@atlassian/jira-list-with-popup/src/ui/main.tsx';
import type { MultiSelectReadViewProps, NonNullableSelectedOptionNode } from './types.tsx';
import messages from './messages.tsx';

/**
 * The MultiSelectReadView will show a read only view of MultiSelect field.
 * @param props [MultiSelectReadViewProps](./types.tsx)
 */
export const MultiSelectReadView = ({
	fragmentRef,
	isTruncated,
	isTruncatedWithHoverPopover,
}: MultiSelectReadViewProps) => {
	const { formatMessage } = useIntl();

	const data = useFragment<MultiSelectReadViewFragment>(
		graphql`
			fragment multiSelect_issueFieldMultiSelectReadviewFull_MultiSelectReadView on JiraMultipleSelectField {
				selectedOptions {
					edges {
						node {
							id
							value
						}
					}
				}
			}
		`,
		fragmentRef,
	);

	const transformedSelectedOptions = useMemo(() => {
		if (!data?.selectedOptions?.edges) {
			return null;
		}

		const selectedOptions = data.selectedOptions.edges
			.map((edge) => edge?.node)
			.filter((node): node is NonNullableSelectedOptionNode => Boolean(node?.value));

		return selectedOptions.map((node) => ({
			key: node.id,
			name: node.value,
			color: null,
			isInteractive: false,
		}));
	}, [data?.selectedOptions?.edges]);

	if (!transformedSelectedOptions?.length) {
		return <EmptyFieldText />;
	}

	if (isTruncated) {
		return (
			<Inline space="space.100" role="group" xcss={isTruncatedWithHoverPopover && inlineStyles}>
				<ListWithPopup
					items={transformedSelectedOptions}
					ItemComponent={isTruncatedWithHoverPopover ? TagItem : OptionTag}
					PopupContentWrapper={TagItemPopupContentWrapper}
					showMoreTooltip={formatMessage(messages.showMoreTooltip)}
					isHoverPopoverEnabled={isTruncatedWithHoverPopover}
				/>
			</Inline>
		);
	}

	return (
		<Inline space="space.100" role="group" shouldWrap>
			{transformedSelectedOptions.map(({ key, ...restProps }) => (
				<OptionTag key={key} {...restProps} />
			))}
		</Inline>
	);
};

const inlineStyles = xcss({
	width: '100%',
});
