import React from 'react';
import { graphql, useFragment } from 'react-relay';
import type { confluencePages_nativeIssueTable_ConfluencePagesCell$key } from '@atlassian/jira-relay/src/__generated__/confluencePages_nativeIssueTable_ConfluencePagesCell.graphql';
import { ConfluencePagesInlineEditViewWithIsEditable } from '@atlassian/jira-issue-field-confluence-pages-inline-edit-full/src/ui/confluence-pages/index.tsx';
import { INLINE_EDIT_ANALYTICS_ATTRIBUTES } from '../common/inline-edit-analytics-attributes.tsx';
import { useLazyLoadEditability } from '../../../../services/lazy-editability-loader/index.tsx';
import { useIsInlineEditingExtendedFieldSupportEnabled } from '../../../../controllers/features/selectors.tsx';

export type Props = {
	fieldRef: confluencePages_nativeIssueTable_ConfluencePagesCell$key;
};

// TODO JSC-1508 Confluence pages field returns an error when trying to lazily fetch isEditableInIssueView. This
//  condition should be removed once the BE data fetcher is fixed.
export const useIsConfluencePagesDataFetcherFixed = () => false;

export const ConfluencePagesCell = ({ fieldRef }: Props) => {
	const data = useFragment<confluencePages_nativeIssueTable_ConfluencePagesCell$key>(
		graphql`
			fragment confluencePages_nativeIssueTable_ConfluencePagesCell on JiraConfluenceRemoteIssueLinksField
			@argumentDefinitions(isInlineEditingEnabled: { type: "Boolean!" }) {
				id
				lazyIsEditableInIssueView @include(if: $isInlineEditingEnabled)
				...confluencePages_issueFieldConfluencePagesInlineEditFull_ConfluencePagesInlineEditViewWithIsEditable_fragmentRef
			}
		`,
		fieldRef,
	);

	const isConfluencePagesDataFetcherFixed = useIsConfluencePagesDataFetcherFixed();
	const isInlineEditingExtendedFieldSupportEnabled =
		useIsInlineEditingExtendedFieldSupportEnabled();
	// If the property does not exist in fragment data then we know it has been conditionally excluded because inline
	// editing is disabled. In this case we explicitly set isEditable to false to bypass the lazy editability query.
	const isEditable =
		'lazyIsEditableInIssueView' in data &&
		isInlineEditingExtendedFieldSupportEnabled &&
		isConfluencePagesDataFetcherFixed
			? data.lazyIsEditableInIssueView
			: false;
	useLazyLoadEditability(data.id, isEditable);

	return (
		<ConfluencePagesInlineEditViewWithIsEditable
			attributes={INLINE_EDIT_ANALYTICS_ATTRIBUTES}
			spacing="default"
			editViewPopup
			editViewPopupAlignBlock="center"
			fragmentRef={data}
			isEditable={isEditable ?? false}
			menuPosition="fixed"
			readViewFitContainerHeight
		/>
	);
};
