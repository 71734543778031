import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	realtimeUpdateExceededMaxHeader: {
		id: 'native-issue-table.ui.realtime-updater.realtime-update-exceeded-max-header',
		defaultMessage: 'Updating those work items',
		description:
			'Header that shows when the data could be stale because we could not process the real time updates',
	},
	realtimeUpdateExceededMaxDescription: {
		id: 'native-issue-table.ui.realtime-updater.realtime-update-exceeded-max-description',
		defaultMessage: 'Refresh the page to see the updates',
		description:
			'Description that shows when the data could be stale because we could not process the real time updates',
	},
	realtimeUpdateExceededMaxRefreshButton: {
		id: 'native-issue-table.ui.realtime-updater.realtime-update-exceeded-max-refresh-button',
		defaultMessage: 'Refresh',
		description: 'Call to action to refresh the page  when the data could be stale',
	},
});
