import React, { useCallback, useMemo, useState } from 'react';

import { useFragment, useMutation, graphql } from 'react-relay';
import { v4 as uuid } from 'uuid';
import type { ValidationFieldProps } from '@atlassian/jira-issue-field-inline-edit-lite/src/ui/field-inline-edit-lite/types.tsx';
import { FieldInlineEditLiteWithEntryPoint } from '@atlassian/jira-issue-field-inline-edit-lite/src/ui/index.tsx';
import { useFieldInlineEditActions } from '@atlassian/jira-issue-field-inline-edit-actions/src/controllers/index.tsx';

import type { ConfluencePagesEditViewProps } from '@atlassian/jira-issue-field-confluence-pages-editview-full/src/ui/confluence-pages/types.tsx';
import ConfluencePagesEditViewEntryPoint from '@atlassian/jira-issue-field-confluence-pages-editview-full/src/entrypoint.tsx';
import { ConfluencePagesReadView } from '@atlassian/jira-issue-field-confluence-pages-readview-full/src/ui/confluence-pages/index.tsx';
import type { confluencePages_issueFieldConfluencePagesInlineEditFull_ConfluencePagesInlineEditView_fragmentRef$key as ConfluencePagesFragment } from '@atlassian/jira-relay/src/__generated__/confluencePages_issueFieldConfluencePagesInlineEditFull_ConfluencePagesInlineEditView_fragmentRef.graphql';
import type { confluencePages_issueFieldConfluencePagesInlineEditFull_ConfluencePagesInlineEditViewWithIsEditable_fragmentRef$key as ConfluencePagesWithIsEditableFragment } from '@atlassian/jira-relay/src/__generated__/confluencePages_issueFieldConfluencePagesInlineEditFull_ConfluencePagesInlineEditViewWithIsEditable_fragmentRef.graphql';
import type { confluencePages_issueFieldConfluencePagesInlineEditFull_Mutation as ConfluencePagesMutation } from '@atlassian/jira-relay/src/__generated__/confluencePages_issueFieldConfluencePagesInlineEditFull_Mutation.graphql';
import type { SmartLinkOption } from '@atlassian/jira-issue-smart-links-popup-edit-view/src/common/types.tsx';
import type { OnSubmitCallbacks } from '@atlassian/jira-issue-field-inline-edit-actions/src/controllers/types.tsx';
import type {
	ConfluencePagesInlineEditViewProps,
	ConfluencePagesInlineEditViewWithIsEditableProps,
} from './types.tsx';

/** Determine if the updated edit view value is equal to our original value before submitting a mutation. */
const isEqualOption = (a: SmartLinkOption[], b: SmartLinkOption[]) => {
	if (a.length !== b.length) return false;

	const aIdSet = new Set(a.map((option) => option.url));
	return b.every((option) => aIdSet.has(option.url));
};

/**
 * Inline edit will handle the switching behaviour between the 'readView' and 'editView' components. This variant allows
 * consumers to define their own value to determine whether the field is editable.
 *
 * In most cases consumers should use `ConfluencePagesInlineEditView` which will enforce that the user has permission to
 * edit the field within the issue view. However, this component can be used for experiences that have differing
 * permissions or want finer control for how this data is retrieved, e.g. lazy loading editability.
 *
 * @param props [ConfluencePagesInlineEditViewWithIsEditableProps](./types.tsx)
 */
export const ConfluencePagesInlineEditViewWithIsEditable = ({
	editViewPopup,
	editViewPopupAlignBlock,
	fragmentRef,
	isEditable,
	isEditing: startWithEditViewOpen = false,
	menuPosition,
	onSubmit,
	onSubmitFailed,
	onSubmitSucceeded,
	readViewFitContainerHeight,
	spacing = 'compact',
}: ConfluencePagesInlineEditViewWithIsEditableProps) => {
	// #region Relay
	const data = useFragment<ConfluencePagesWithIsEditableFragment>(
		graphql`
			fragment confluencePages_issueFieldConfluencePagesInlineEditFull_ConfluencePagesInlineEditViewWithIsEditable_fragmentRef on JiraConfluenceRemoteIssueLinksField {
				id
				name
				type
				...confluencePages_issueFieldConfluencePagesReadviewFull_ConfluencePagesReadView
				confluenceRemoteIssueLinks(first: 1000) @optIn(to: "JiraConfluenceRemoteIssueLinksField") {
					edges {
						node {
							id
							href
						}
					}
				}
			}
		`,
		fragmentRef,
	);

	const [commit] = useMutation<ConfluencePagesMutation>(graphql`
		mutation confluencePages_issueFieldConfluencePagesInlineEditFull_Mutation(
			$input: JiraUpdateConfluenceRemoteIssueLinksFieldInput!
		) @raw_response_type {
			jira @optIn(to: ["JiraIssueFieldMutations"]) {
				updateConfluenceRemoteIssueLinksField(input: $input) {
					success
					errors {
						message
					}
					field {
						...confluencePages_issueFieldConfluencePagesReadviewFull_ConfluencePagesReadView
						confluenceRemoteIssueLinks(first: 1000)
							@optIn(to: "JiraConfluenceRemoteIssueLinksField") {
							edges {
								node {
									id
									href
								}
							}
						}
					}
				}
			}
		}
	`);
	// #endregion

	// #region Common state
	const { id: uniqueFieldId, name: fieldName, type: fieldType, confluenceRemoteIssueLinks } = data;

	const initialValue = useMemo(
		(): SmartLinkOption[] =>
			confluenceRemoteIssueLinks?.edges
				?.map((edge) => edge?.node)
				.filter(Boolean)
				.map((node) => ({
					id: node.id,
					url: node.href ?? '',
				})) ?? [],
		[confluenceRemoteIssueLinks],
	);
	const [updatedValue, setUpdatedValue] = useState<SmartLinkOption[]>(initialValue);

	const handleSubmit = useCallback(
		(value: SmartLinkOption[], { onSuccess, onFail }: OnSubmitCallbacks) => {
			onSubmit?.(value);

			commit({
				variables: {
					input: {
						id: uniqueFieldId,
						operations: [
							{
								links: value.map((option) => ({ href: option.url })),
								operation: 'SET',
							},
						],
					},
				},

				onCompleted(mutationData) {
					if (mutationData.jira?.updateConfluenceRemoteIssueLinksField?.success) {
						onSuccess();
					} else {
						onFail();
					}
				},
				onError(error) {
					onFail(error);
				},
				optimisticResponse: {
					jira: {
						updateConfluenceRemoteIssueLinksField: {
							success: true,
							errors: null,
							field: {
								id: uniqueFieldId,
								confluenceRemoteIssueLinks: {
									totalCount: value.length,
									edges: value.map((option) => ({
										node: {
											id: option.id ?? uuid(),
											href: option.url,
										},
									})),
								},
							},
						},
					},
				},
			});
		},
		[commit, onSubmit, uniqueFieldId],
	);

	const {
		invalidMessage,
		isEditing,
		hasServerValidationError,
		handleCancel,
		handleEdit,
		handleConfirm,
		handleChange,
	} = useFieldInlineEditActions({
		fieldId: uniqueFieldId,
		fieldName,
		fieldType,
		initialValue,
		isValueEqual: isEqualOption,
		onSubmit: handleSubmit,
		onSubmitFailed,
		onSubmitSucceeded,
		onUpdateValue: setUpdatedValue,
		startWithEditViewOpen,
		updatedValue,
	});

	// #region Read view
	const renderReadView = () => <ConfluencePagesReadView fragmentRef={data} />;
	// #endregion

	// #region Edit view
	const getEditViewProps = (fieldProps: ValidationFieldProps): ConfluencePagesEditViewProps => ({
		...fieldProps,
		initialIsOpen: true,
		value: updatedValue,
		menuPosition,
		onChange: handleChange,
		spacing,
	});
	// #endregion

	return (
		<FieldInlineEditLiteWithEntryPoint
			editViewPopup={editViewPopup}
			editViewPopupAlignBlock={editViewPopupAlignBlock}
			editViewEntryPoint={ConfluencePagesEditViewEntryPoint}
			editViewEntryPointParams={{}}
			getEditViewProps={getEditViewProps}
			fieldName={fieldName}
			hasUnsubmittedChanges={hasServerValidationError}
			invalidMessage={invalidMessage}
			isEditing={isEditing}
			isEditable={isEditable}
			onCancel={handleCancel}
			onConfirm={handleConfirm}
			onEdit={handleEdit}
			readViewFitContainerHeight={readViewFitContainerHeight}
			readView={renderReadView}
			hideActionButtons
		/>
	);
};

/**
 * Inline edit will handle the switching behaviour between the 'readView' and 'editView' components.
 *
 * @param props [ConfluencePagesInlineEditViewProps](./types.tsx)
 */
export const ConfluencePagesInlineEditView = ({
	fragmentRef,
	...props
}: ConfluencePagesInlineEditViewProps) => {
	// #region Relay
	const data = useFragment<ConfluencePagesFragment>(
		graphql`
			fragment confluencePages_issueFieldConfluencePagesInlineEditFull_ConfluencePagesInlineEditView_fragmentRef on JiraConfluenceRemoteIssueLinksField {
				...confluencePages_issueFieldConfluencePagesInlineEditFull_ConfluencePagesInlineEditViewWithIsEditable_fragmentRef
				fieldConfig {
					isEditable
				}
			}
		`,
		fragmentRef,
	);
	// #endregion

	return (
		<ConfluencePagesInlineEditViewWithIsEditable
			{...props}
			fragmentRef={data}
			isEditable={data.fieldConfig?.isEditable ?? false}
		/>
	);
};
