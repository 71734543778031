import React from 'react';
import { Inline } from '@atlaskit/primitives';
import { fg } from '@atlassian/jira-feature-gating';
import ShowMorePopup, { type Props as PopupProps } from './show-more-popup/index.tsx';

export type Props = {
	/**
	 * The component with the first item to be rendered before the popup
	 */
	ItemComponent: React.JSX.Element | undefined;
} & PopupProps;

const ItemWithPopup = ({
	ItemComponent,
	showMoreTooltip,
	totalItems,
	PopupComponent,
	isOpen,
	onSetIsOpen,
	triggerRefCallback,
	actionSubjectIdPrefix,
	isTruncated = false,
}: Props) => {
	if (ItemComponent === undefined) {
		return null;
	}

	const shouldTruncated = isTruncated && fg('jsc_inline_editing_field_refactor');
	return (
		<Inline space="space.100" alignBlock="center" shouldWrap={!shouldTruncated}>
			{ItemComponent}
			{totalItems > 1 && (
				<ShowMorePopup
					showMoreTooltip={showMoreTooltip}
					totalItems={totalItems - 1}
					PopupComponent={PopupComponent}
					isOpen={isOpen}
					onSetIsOpen={onSetIsOpen}
					triggerRefCallback={triggerRefCallback}
					actionSubjectIdPrefix={actionSubjectIdPrefix}
				/>
			)}
		</Inline>
	);
};

export default ItemWithPopup;
