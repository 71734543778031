/**
 * @generated SignedSource<<78c7b216eaaf03855c4eb02e4bb26794>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type goals_nativeIssueTable_GoalsCell$data = {
  readonly " $fragmentSpreads": FragmentRefs<"goals_issueFieldGoalsReadviewFull_GoalsReadView">;
  readonly " $fragmentType": "goals_nativeIssueTable_GoalsCell";
};
export type goals_nativeIssueTable_GoalsCell$key = {
  readonly " $data"?: goals_nativeIssueTable_GoalsCell$data;
  readonly " $fragmentSpreads": FragmentRefs<"goals_nativeIssueTable_GoalsCell">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "goals_nativeIssueTable_GoalsCell",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "goals_issueFieldGoalsReadviewFull_GoalsReadView"
    }
  ],
  "type": "JiraGoalsField"
};

(node as any).hash = "8f91a4ba8d65d05d79763740da3b2a77";

export default node;
