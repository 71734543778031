import React, { useCallback, useMemo, useState } from 'react';

import { useFragment, graphql, useMutation } from 'react-relay';
import { useFieldInlineEditActions } from '@atlassian/jira-issue-field-inline-edit-actions/src/controllers/index.tsx';

import type { OnSubmitCallbacks } from '@atlassian/jira-issue-field-inline-edit-actions/src/controllers/types.tsx';
import type { ValidationFieldProps } from '@atlassian/jira-issue-field-inline-edit-lite/src/ui/field-inline-edit-lite/types.tsx';
import { FieldInlineEditLiteWithEntryPoint } from '@atlassian/jira-issue-field-inline-edit-lite/src/ui/index.tsx';

import type {
	Option,
	CheckboxSelectEditViewProps,
} from '@atlassian/jira-issue-field-checkbox-select-editview-full/src/ui/checkbox-select/types.tsx';
import CheckboxSelectEditViewEntryPoint from '@atlassian/jira-issue-field-checkbox-select-editview-full/src/entrypoint.tsx';
import { CheckboxSelectReadView } from '@atlassian/jira-issue-field-checkbox-select-readview-full/src/ui/checkbox-select/index.tsx';
import type { checkboxSelect_issueFieldCheckboxSelect_CheckboxSelectField_Mutation as CheckboxSelectMutation } from '@atlassian/jira-relay/src/__generated__/checkboxSelect_issueFieldCheckboxSelect_CheckboxSelectField_Mutation.graphql';
import type { checkboxSelect_issueFieldCheckboxSelectInlineEditFull_CheckboxSelectInlineEditView_fragmentRef$key as CheckboxSelectFragment } from '@atlassian/jira-relay/src/__generated__/checkboxSelect_issueFieldCheckboxSelectInlineEditFull_CheckboxSelectInlineEditView_fragmentRef.graphql';
import type { checkboxSelect_issueFieldCheckboxSelectInlineEditFull_CheckboxSelectInlineEditViewWithIsEditable_fragmentRef$key as CheckboxSelectWithIsEditableFragment } from '@atlassian/jira-relay/src/__generated__/checkboxSelect_issueFieldCheckboxSelectInlineEditFull_CheckboxSelectInlineEditViewWithIsEditable_fragmentRef.graphql';
import { useInlineEditFieldInjections } from '@atlassian/jira-issue-field-injections/src/controllers/inline-edit-injections-context/index.tsx';
import type {
	CheckboxSelectInlineEditViewProps,
	CheckboxSelectInlineEditViewWithIsEditableProps,
} from './types.tsx';

/** Determine if the updated edit view value is equal to our original value before submitting a mutation. */
const isEqualOption = (a: Option[] | null, b: Option[] | null) => {
	if (a === null || b === null) return a === b;
	if (a.length !== b.length) return false;

	const aIdSet = new Set(a.map((option) => option.value));
	return b.every((option) => aIdSet.has(option.value));
};

/**
 * Inline edit will handle the switching behaviour between the 'readView' and 'editView' components. This variant allows
 * consumers to define their own value to determine whether the field is editable.
 *
 * In most cases consumers should use `CheckboxSelectInlineEditView` which will enforce that the user has permission to
 * edit the field within the issue view. However, this component can be used for experiences that have differing
 * permissions or want finer control for how this data is retrieved, e.g. lazy loading editability.
 *
 * @param props [CheckboxSelectInlineEditViewWithIsEditableProps](./types.tsx)
 */
export const CheckboxSelectInlineEditViewWithIsEditable = ({
	attributes,
	editViewPopup,
	editViewPopupAlignBlock,
	fragmentRef,
	isEditable,
	isEditing: startWithEditViewOpen = false,
	isTruncated,
	menuPosition,
	menuPortalTarget,
	onSubmit,
	onSubmitFailed,
	onSubmitSucceeded,
	readViewFitContainerHeight,
	spacing = 'compact',
	isSingleLineRowHeightEnabled,
}: CheckboxSelectInlineEditViewWithIsEditableProps) => {
	const data = useFragment<CheckboxSelectWithIsEditableFragment>(
		graphql`
			fragment checkboxSelect_issueFieldCheckboxSelectInlineEditFull_CheckboxSelectInlineEditViewWithIsEditable_fragmentRef on JiraCheckboxesField {
				id
				name
				fieldId
				type
				...checkboxSelect_issueFieldCheckboxSelectReadviewFull_CheckboxSelectReadView
				selectedOptions {
					edges {
						node {
							id
							value
						}
					}
				}
			}
		`,
		fragmentRef,
	);

	const [commit] = useMutation<CheckboxSelectMutation>(graphql`
		mutation checkboxSelect_issueFieldCheckboxSelect_CheckboxSelectField_Mutation(
			$input: JiraUpdateCheckboxesFieldInput!
		) @raw_response_type {
			jira @optIn(to: ["JiraIssueFieldMutations"]) {
				updateCheckboxesField(input: $input) {
					success
					errors {
						message
					}
					field {
						...checkboxSelect_issueFieldCheckboxSelectInlineEditFull_CheckboxSelectInlineEditViewWithIsEditable_fragmentRef
					}
				}
			}
		}
	`);

	const { id: uniqueFieldId, name, selectedOptions, fieldId, type: fieldType } = data;

	const initialValue: Option[] | null = useMemo(() => {
		if (!selectedOptions?.edges) {
			return null;
		}

		return selectedOptions.edges.map((edge) => ({
			value: edge?.node?.id ?? '',
			label: edge?.node?.value ?? '',
		}));
	}, [selectedOptions?.edges]);

	const [updatedValue, setUpdatedValue] = useState<Option[] | null>(initialValue);

	const { overriding } = useInlineEditFieldInjections();
	const { overrideLabel, overrideIsEditable } = overriding;

	const fieldName = useMemo(() => overrideLabel(name), [overrideLabel, name]);

	const isFieldEditable = useMemo(
		() => overrideIsEditable(isEditable),
		[overrideIsEditable, isEditable],
	);

	const handleSubmit = useCallback(
		(values: Option[] | null, { onSuccess, onFail }: OnSubmitCallbacks) => {
			onSubmit?.(values);

			commit({
				variables: {
					input: {
						id: uniqueFieldId,
						operations: [
							{
								operation: 'SET',
								ids: values?.map((selection) => selection.value) ?? [],
							},
						],
					},
				},
				onCompleted(response) {
					if (response.jira?.updateCheckboxesField?.success) {
						onSuccess();
					} else {
						onFail();
					}
				},
				onError(error: Error) {
					onFail(error);
				},
				optimisticResponse: {
					jira: {
						updateCheckboxesField: {
							success: true,
							errors: null,
							field: {
								id: uniqueFieldId,
								name: fieldName,
								fieldId,
								type: fieldType,
								selectedOptions: {
									edges:
										values?.map((edge) => ({
											node: {
												id: edge.value ?? '',
												value: edge.label ?? '',
											},
										})) ?? [],
								},
							},
						},
					},
				},
			});
		},
		[commit, fieldId, fieldName, fieldType, onSubmit, uniqueFieldId],
	);

	const {
		invalidMessage,
		isEditing,
		hasServerValidationError,
		handleCancel,
		handleEdit,
		handleConfirm,
		handleChange,
		handleCopy,
		handleCut,
		handlePaste,
	} = useFieldInlineEditActions({
		attributes,
		fieldId,
		fieldName,
		fieldType,
		initialValue,
		isValueEqual: isEqualOption,
		onSubmit: handleSubmit,
		onSubmitFailed,
		onSubmitSucceeded,
		onUpdateValue: setUpdatedValue,
		startWithEditViewOpen,
		updatedValue,
		clipboardComponentType: 'checkboxSelect',
	});

	const renderReadView = useCallback(
		() => (
			<CheckboxSelectReadView
				fragmentRef={data}
				isTruncated={isTruncated}
				isHoverPopoverEnabled={isSingleLineRowHeightEnabled}
			/>
		),
		[data, isTruncated, isSingleLineRowHeightEnabled],
	);

	const getEditViewProps = (fieldProps: ValidationFieldProps): CheckboxSelectEditViewProps => ({
		...fieldProps,
		autoFocus: true,
		fieldId: uniqueFieldId,
		fieldName,
		value: updatedValue,
		menuPosition,
		menuPortalTarget,
		onChange: handleChange,
		fetchSuggestionsOnMount: true,
		spacing,
	});

	return (
		<FieldInlineEditLiteWithEntryPoint
			editViewPopup={editViewPopup}
			editViewPopupAlignBlock={editViewPopupAlignBlock}
			editViewPopupMinWidth="small"
			editViewEntryPoint={CheckboxSelectEditViewEntryPoint}
			editViewEntryPointParams={{}}
			getEditViewProps={getEditViewProps}
			fieldName={fieldName}
			hasUnsubmittedChanges={hasServerValidationError}
			invalidMessage={invalidMessage}
			isEditing={isEditing}
			isEditable={isFieldEditable}
			onCancel={handleCancel}
			onConfirm={handleConfirm}
			onEdit={handleEdit}
			onCopy={handleCopy}
			onCut={handleCut}
			onPaste={handlePaste}
			readViewFitContainerHeight={readViewFitContainerHeight}
			readView={renderReadView}
			hideActionButtons
			hasReadViewTruncateDisabled={isSingleLineRowHeightEnabled}
		/>
	);
};

/**
 * Inline edit will handle the switching behaviour between the 'readView' and 'editView' components.
 *
 * @param props [CheckboxSelectInlineEditViewProps](./types.tsx)
 */
export const CheckboxSelectInlineEditView = ({
	fragmentRef,
	...props
}: CheckboxSelectInlineEditViewProps) => {
	/**
	 * This fragment should spread the inner InlineEditViewWithIsEditable fragment and read the isEditable flag from the
	 * fieldConfig.
	 */
	const data = useFragment<CheckboxSelectFragment>(
		graphql`
			fragment checkboxSelect_issueFieldCheckboxSelectInlineEditFull_CheckboxSelectInlineEditView_fragmentRef on JiraCheckboxesField {
				...checkboxSelect_issueFieldCheckboxSelectInlineEditFull_CheckboxSelectInlineEditViewWithIsEditable_fragmentRef
				fieldConfig {
					isEditable
				}
			}
		`,
		fragmentRef,
	);

	return (
		<CheckboxSelectInlineEditViewWithIsEditable
			{...props}
			fragmentRef={data}
			isEditable={data.fieldConfig?.isEditable ?? false}
		/>
	);
};
