import { useCallback, useMemo, useRef, useEffect } from 'react';
import throttle from 'lodash/throttle';
import { graphql, useFragment, useSubscription } from 'react-relay';
import type { GraphQLSubscriptionConfig } from 'relay-runtime';
import type { realtimeUpdater_issueCreated_Subscription as IssueCreateSubscription } from '@atlassian/jira-relay/src/__generated__/realtimeUpdater_issueCreated_Subscription.graphql';
import type { realtimeUpdater_issueDeleted_Subscription as IssueDeleteSubscription } from '@atlassian/jira-relay/src/__generated__/realtimeUpdater_issueDeleted_Subscription.graphql';
import type { realtimeUpdater_issueUpdated_Subscription as IssueUpdateSubscription } from '@atlassian/jira-relay/src/__generated__/realtimeUpdater_issueUpdated_Subscription.graphql';
import type { realtimeUpdater_nativeIssueTable_groups$key } from '@atlassian/jira-relay/src/__generated__/realtimeUpdater_nativeIssueTable_groups.graphql';
import type { realtimeUpdater_nativeIssueTable_issues$key } from '@atlassian/jira-relay/src/__generated__/realtimeUpdater_nativeIssueTable_issues.graphql.ts';
import type { realtimeUpdater_nativeIssueTable_project$key } from '@atlassian/jira-relay/src/__generated__/realtimeUpdater_nativeIssueTable_project.graphql.ts';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import {
	fireTrackAnalytics,
	useAnalyticsEvents,
	fireOperationalAnalytics,
	fireUIAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { useIssueRealtimeUpdater } from '../../controllers/issue-realtime-updater/index.tsx';
import { PACKAGE_NAME, TEAM_NAME } from '../../common/constants.tsx';
import { useRealtimeUpdateExceededMaxFlag, useRealtimeUpdatesForIssues } from './utils.tsx';

const REAL_TIME_EVENT_TYPE_KEY = 'realTimeEventType';
const CREATE_ERROR_ID = 'realTimeIssueCreate';
const UPDATE_ERROR_ID = 'realTimeIssueUpdate';
const DELETE_ERROR_ID = 'realTimeIssueDelete';

export const MAX_ALLOWED_QUEUE_SIZE = 10;
export const QUERY_ISSUE_DETAILS_DELAY_TIME = 3000;

const throttledFireTrackAnalytics = throttle(fireTrackAnalytics, 1000);

type Props = {
	project: realtimeUpdater_nativeIssueTable_project$key;
	issues?: realtimeUpdater_nativeIssueTable_issues$key | null;
	groups?: realtimeUpdater_nativeIssueTable_groups$key | null;
	isHierarchyEnabled?: boolean;
	isStatusTrackingEnabled?: boolean;
	onRefetch?: () => void;
};

const issueCreatedSubscription = graphql`
	subscription realtimeUpdater_issueCreated_Subscription($cloudId: ID!, $projectId: String!) {
		jira {
			onIssueCreatedByProject(cloudId: $cloudId, projectId: $projectId) {
				key
			}
		}
	}
`;

const issueUpdatedSubscription = graphql`
	subscription realtimeUpdater_issueUpdated_Subscription($cloudId: ID!, $projectId: String!) {
		jira {
			onIssueUpdatedByProject(cloudId: $cloudId, projectId: $projectId) {
				key
			}
		}
	}
`;

const issueDeletedSubscription = graphql`
	subscription realtimeUpdater_issueDeleted_Subscription($cloudId: ID!, $projectId: String!) {
		jira {
			onIssueDeletedByProject(cloudId: $cloudId, projectId: $projectId) {
				resource
			}
		}
	}
`;

export const RealtimeUpdater = ({
	project,
	issues,
	groups,
	isHierarchyEnabled,
	isStatusTrackingEnabled,
	onRefetch,
}: Props) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const hasExceededMaxQueueSizeRef = useRef(false);
	const showRealtimeUpdateExceededMaxFlag = useRealtimeUpdateExceededMaxFlag();
	const realtimeUpdatesForIssues = useRealtimeUpdatesForIssues();
	const cloudId = useCloudId();

	const issueConnection = useFragment(
		graphql`
			fragment realtimeUpdater_nativeIssueTable_issues on JiraIssueConnection {
				__id
				jql
				edges {
					__typename
				}
			}
		`,
		issues ?? null,
	);

	const groupConnection = useFragment(
		graphql`
			fragment realtimeUpdater_nativeIssueTable_groups on JiraSpreadsheetGroupConnection {
				__id
				jql
				groupByField
				edges {
					__typename
				}
			}
		`,
		groups ?? null,
	);

	const { projectId, key: projectKey } = useFragment(
		graphql`
			fragment realtimeUpdater_nativeIssueTable_project on JiraProject {
				projectId @required(action: THROW)
				key
			}
		`,
		project,
	);

	const { issueDeleteUpdater } = useIssueRealtimeUpdater();

	const variables = useMemo(
		() => ({
			cloudId,
			projectId,
		}),
		[cloudId, projectId],
	);

	const queueRef = useRef<string[]>([]);
	const timeoutRef = useRef<NodeJS.Timeout | null>(null);
	const processQueue = useCallback(() => {
		if (queueRef.current.length === 0 || hasExceededMaxQueueSizeRef.current) {
			return;
		}

		fireOperationalAnalytics(
			createAnalyticsEvent({}),
			'issueTableRealTimeEventQueueProcessing started',
			{ queueSize: queueRef.current.length },
		);

		if (queueRef.current.length > MAX_ALLOWED_QUEUE_SIZE) {
			fireOperationalAnalytics(
				createAnalyticsEvent({}),
				'issueTableRealTimeEventsExceededMaxFlag shown',
				{ queueSize: queueRef.current.length },
			);

			hasExceededMaxQueueSizeRef.current = true;
			showRealtimeUpdateExceededMaxFlag({
				onRefresh: () => {
					fireUIAnalytics(
						createAnalyticsEvent({}),
						'issueTableRealTimeEventsExceededMaxRefresh clicked',
					);
					hasExceededMaxQueueSizeRef.current = false;
					queueRef.current = [];
					onRefetch?.();
				},
			});
		} else {
			realtimeUpdatesForIssues({
				keys: queueRef.current,
				isHierarchyEnabled: isHierarchyEnabled ?? false,
				jql: issueConnection?.jql || groupConnection?.jql || '',
				groupByFieldId: groupConnection?.groupByField || undefined,
				groupConnectionId: groupConnection?.__id,
				numGroupsLoaded: groupConnection?.edges?.length ?? 0,
				projectKey,
				isStatusTrackingEnabled: isStatusTrackingEnabled ?? false,
			});
		}

		queueRef.current = [];
	}, [
		createAnalyticsEvent,
		showRealtimeUpdateExceededMaxFlag,
		onRefetch,
		realtimeUpdatesForIssues,
		isHierarchyEnabled,
		issueConnection?.jql,
		groupConnection?.jql,
		groupConnection?.groupByField,
		groupConnection?.__id,
		groupConnection?.edges?.length,
		projectKey,
		isStatusTrackingEnabled,
	]);

	const triggerDelayedProcessingOfQueue = useCallback(() => {
		// Existing timeout exists, so we don't need to create a new one
		if (timeoutRef.current) {
			return;
		}

		timeoutRef.current = setTimeout(() => {
			processQueue();
			timeoutRef.current = null;
		}, QUERY_ISSUE_DETAILS_DELAY_TIME);
	}, [processQueue]);

	useEffect(() => {
		return () => {
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
			}
		};
	}, []);

	const handleCreateOrUpdateEvent = useCallback(
		(key: string) => {
			queueRef.current.push(key);

			triggerDelayedProcessingOfQueue();
		},
		[triggerDelayedProcessingOfQueue],
	);
	const handleCreateOrUpdateEventRef = useRef(handleCreateOrUpdateEvent);

	const createConfig = useMemo<GraphQLSubscriptionConfig<IssueCreateSubscription>>(
		() => ({
			variables,
			subscription: issueCreatedSubscription,
			onNext: async (response) => {
				try {
					if (response?.jira?.onIssueCreatedByProject) {
						handleCreateOrUpdateEventRef.current(response.jira.onIssueCreatedByProject.key);
					}
				} catch (error) {
					fireErrorAnalytics({
						meta: {
							id: CREATE_ERROR_ID,
							packageName: PACKAGE_NAME,
							teamName: TEAM_NAME,
						},
						attributes: { logLocation: 'onNext' },
						error: error instanceof Error ? error : undefined,
						sendToPrivacyUnsafeSplunk: true,
					});
				}
			},
			onError: (error) => {
				fireErrorAnalytics({
					meta: {
						id: CREATE_ERROR_ID,
						packageName: PACKAGE_NAME,
						teamName: TEAM_NAME,
					},
					attributes: { logLocation: 'onError' },
					error,
					sendToPrivacyUnsafeSplunk: true,
				});
			},
		}),
		[variables],
	);

	const updateConfig = useMemo<GraphQLSubscriptionConfig<IssueUpdateSubscription>>(
		() => ({
			variables,
			subscription: issueUpdatedSubscription,
			onNext: async (response) => {
				try {
					if (response?.jira?.onIssueUpdatedByProject) {
						handleCreateOrUpdateEventRef.current(response.jira.onIssueUpdatedByProject.key);
					}
				} catch (error) {
					fireErrorAnalytics({
						meta: {
							id: UPDATE_ERROR_ID,
							packageName: PACKAGE_NAME,
							teamName: TEAM_NAME,
						},
						attributes: { logLocation: 'onNext' },
						error: error instanceof Error ? error : undefined,
						sendToPrivacyUnsafeSplunk: true,
					});
				}
			},
			onError: (error) => {
				fireErrorAnalytics({
					meta: {
						id: UPDATE_ERROR_ID,
						packageName: PACKAGE_NAME,
						teamName: TEAM_NAME,
					},
					attributes: { logLocation: 'onError' },
					error,
					sendToPrivacyUnsafeSplunk: true,
				});
			},
		}),
		[variables],
	);

	const deleteConfig = useMemo<GraphQLSubscriptionConfig<IssueDeleteSubscription>>(() => {
		return {
			variables,
			subscription: issueDeletedSubscription,
			updater: (store, response) => {
				try {
					if (response?.jira?.onIssueDeletedByProject?.resource) {
						const wasDeleted = issueDeleteUpdater({
							store,
							issueResourceId: response.jira.onIssueDeletedByProject.resource,
							groupConnectionId: groupConnection?.__id,
							projectKey,
						});

						wasDeleted &&
							throttledFireTrackAnalytics(
								createAnalyticsEvent({}),
								'issueTableRealTimeEvent processed',
								{ [REAL_TIME_EVENT_TYPE_KEY]: 'delete' },
							);
					}
				} catch (error) {
					fireErrorAnalytics({
						meta: {
							id: DELETE_ERROR_ID,
							packageName: PACKAGE_NAME,
							teamName: TEAM_NAME,
						},
						attributes: { logLocation: 'updater' },
						error: error instanceof Error ? error : undefined,
						sendToPrivacyUnsafeSplunk: true,
					});
				}
			},
			onError: (error) => {
				fireErrorAnalytics({
					meta: {
						id: DELETE_ERROR_ID,
						packageName: PACKAGE_NAME,
						teamName: TEAM_NAME,
					},
					attributes: { logLocation: 'onError' },
					error,
					sendToPrivacyUnsafeSplunk: true,
				});
			},
		};
	}, [variables, issueDeleteUpdater, groupConnection?.__id, projectKey, createAnalyticsEvent]);

	useSubscription(createConfig);
	useSubscription(updateConfig);
	useSubscription(deleteConfig);

	return null;
};
