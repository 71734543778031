import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

const ConfluencePagesEditViewEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() =>
			import(/* webpackChunkName: "async-confluence-pages-edit-view" */ './ui/confluence-pages'),
	),
	getPreloadProps: () => ({}),
});

export default ConfluencePagesEditViewEntryPoint;
