import React, { useCallback, useMemo, useState } from 'react';

import { useFragment, graphql, useMutation } from 'react-relay';
import { useFieldInlineEditActions } from '@atlassian/jira-issue-field-inline-edit-actions/src/controllers/index.tsx';

import type { OnSubmitCallbacks } from '@atlassian/jira-issue-field-inline-edit-actions/src/controllers/types.tsx';
import type { ValidationFieldProps } from '@atlassian/jira-issue-field-inline-edit-lite/src/ui/field-inline-edit-lite/types.tsx';
import { FieldInlineEditLiteWithEntryPoint } from '@atlassian/jira-issue-field-inline-edit-lite/src/ui/index.tsx';

import type { MultiVersionPickerEditViewProps } from '@atlassian/jira-issue-field-multi-version-picker-editview-full/src/ui/multi-version-picker/types.tsx';
import MultiVersionPickerEditViewEntryPoint from '@atlassian/jira-issue-field-multi-version-picker-editview-full/src/entrypoint.tsx';
import { MultiVersionPickerReadView } from '@atlassian/jira-issue-field-multi-version-picker-readview-full/src/ui/multi-version-picker/index.tsx';
import type { multiVersionPicker_issueFieldMultiVersionPicker_MultiVersionPickerField_Mutation as MultiVersionPickerMutation } from '@atlassian/jira-relay/src/__generated__/multiVersionPicker_issueFieldMultiVersionPicker_MultiVersionPickerField_Mutation.graphql';
import type { multiVersionPicker_issueFieldMultiVersionPickerInlineEditFull_MultiVersionPickerInlineEditView_fragmentRef$key as MultiVersionPickerFragment } from '@atlassian/jira-relay/src/__generated__/multiVersionPicker_issueFieldMultiVersionPickerInlineEditFull_MultiVersionPickerInlineEditView_fragmentRef.graphql';
import type { multiVersionPicker_issueFieldMultiVersionPickerInlineEditFull_MultiVersionPickerInlineEditViewWithIsEditable_fragmentRef$key as MultiVersionPickerWithIsEditableFragment } from '@atlassian/jira-relay/src/__generated__/multiVersionPicker_issueFieldMultiVersionPickerInlineEditFull_MultiVersionPickerInlineEditViewWithIsEditable_fragmentRef.graphql';
import { useInlineEditFieldInjections } from '@atlassian/jira-issue-field-injections/src/controllers/inline-edit-injections-context/index.tsx';
import type { NullableOptions } from '@atlassian/jira-issue-selectable-field-edit-view/src/ui/types.tsx';
import type {
	MultiVersionPickerInlineEditViewProps,
	MultiVersionPickerInlineEditViewWithIsEditableProps,
} from './types.tsx';

/** Determine if the updated edit view value is equal to our original value before submitting a mutation. */
const isEqualOption = (a: NullableOptions, b: NullableOptions) => {
	if (a === null || b === null) return a === b;
	if (a.length !== b.length) return false;

	const aIdSet = new Set(a.map((option) => option.value));
	return b.every((option) => aIdSet.has(option.value));
};

/**
 * Inline edit will handle the switching behaviour between the 'readView' and 'editView' multiVersionPicker. This variant allows
 * consumers to define their own value to determine whether the field is editable.
 *
 * In most cases consumers should use `MultiVersionPickerInlineEditView` which will enforce that the user has permission to
 * edit the field within the issue view. However, this component can be used for experiences that have differing
 * permissions or want finer control for how this data is retrieved, e.g. lazy loading editability.
 *
 * @param props [MultiVersionPickerInlineEditViewWithIsEditableProps](./types.tsx)
 */
export const MultiVersionPickerInlineEditViewWithIsEditable = ({
	attributes,
	editViewPopup,
	editViewPopupAlignBlock,
	fragmentRef,
	isEditable,
	isEditing: startWithEditViewOpen = false,
	isTruncated,
	menuPosition,
	menuPortalTarget,
	onSubmit,
	onSubmitFailed,
	onSubmitSucceeded,
	readViewFitContainerHeight,
	spacing = 'compact',
}: MultiVersionPickerInlineEditViewWithIsEditableProps) => {
	const data = useFragment<MultiVersionPickerWithIsEditableFragment>(
		graphql`
			fragment multiVersionPicker_issueFieldMultiVersionPickerInlineEditFull_MultiVersionPickerInlineEditViewWithIsEditable_fragmentRef on JiraMultipleVersionPickerField {
				id
				name
				fieldId
				type
				...multiVersionPicker_issueFieldMultiVersionPickerReadviewFull_MultiVersionPickerReadView
				selectedVersionsConnection(first: 1000) {
					edges {
						node {
							value: id
							label: name
							versionId
						}
					}
				}
			}
		`,
		fragmentRef,
	);

	const [commit] = useMutation<MultiVersionPickerMutation>(graphql`
		mutation multiVersionPicker_issueFieldMultiVersionPicker_MultiVersionPickerField_Mutation(
			$input: JiraUpdateMultipleVersionPickerFieldInput!
		) @raw_response_type {
			jira @optIn(to: ["JiraIssueFieldMutations"]) {
				updateMultipleVersionPickerField(input: $input) {
					success
					errors {
						message
					}
					field {
						...multiVersionPicker_issueFieldMultiVersionPickerInlineEditFull_MultiVersionPickerInlineEditViewWithIsEditable_fragmentRef
					}
				}
			}
		}
	`);

	const {
		id: uniqueFieldId,
		name,
		selectedVersionsConnection: selectedOptions,
		fieldId,
		type: fieldType,
	} = data;

	const initialValue: NullableOptions = useMemo(() => {
		if (!selectedOptions?.edges) {
			return null;
		}

		return selectedOptions.edges.map((edge) => ({
			id: edge?.node?.value ?? '',
			optionId: edge?.node?.versionId ?? '',
			value: edge?.node?.label ?? '',
			selectableLabel: edge?.node?.label ?? '',
			selectableGroupKey: null,
			selectableIconUrl: null,
		}));
	}, [selectedOptions?.edges]);

	const [updatedValue, setUpdatedValue] = useState<NullableOptions>(initialValue);

	const { overriding } = useInlineEditFieldInjections();
	const { overrideLabel, overrideIsEditable } = overriding;

	const fieldName = useMemo(() => overrideLabel(name), [overrideLabel, name]);

	const isFieldEditable = useMemo(
		() => overrideIsEditable(isEditable),
		[overrideIsEditable, isEditable],
	);

	const handleSubmit = useCallback(
		(values: NullableOptions, { onSuccess, onFail }: OnSubmitCallbacks) => {
			onSubmit?.(values);

			commit({
				variables: {
					input: {
						id: uniqueFieldId,
						operations: [
							{
								operation: 'SET',
								ids: values?.map((selection) => selection.id) ?? [],
							},
						],
					},
				},
				onCompleted(response) {
					if (response.jira?.updateMultipleVersionPickerField?.success) {
						onSuccess();
					} else {
						onFail();
					}
				},
				onError(error: Error) {
					onFail(error);
				},
				optimisticResponse: {
					jira: {
						updateMultipleVersionPickerField: {
							success: true,
							errors: null,
							field: {
								id: uniqueFieldId,
								name: fieldName,
								fieldId,
								type: fieldType,
								selectedVersionsConnection: {
									edges:
										values?.map((edge) => ({
											node: {
												id: edge.id ?? '',
												name: edge.selectableLabel ?? '',
												label: edge.selectableLabel ?? '',
												value: edge.id ?? '',
												versionId: edge.optionId ?? '',
											},
										})) ?? [],
								},
							},
						},
					},
				},
			});
		},
		[commit, fieldId, fieldName, fieldType, onSubmit, uniqueFieldId],
	);

	const {
		invalidMessage,
		isEditing,
		hasServerValidationError,
		handleCancel,
		handleEdit,
		handleCopy,
		handleCut,
		handlePaste,
		handleConfirm,
		handleChange,
	} = useFieldInlineEditActions({
		attributes,
		fieldId,
		fieldName,
		fieldType,
		initialValue,
		isValueEqual: isEqualOption,
		onSubmit: handleSubmit,
		onSubmitFailed,
		onSubmitSucceeded,
		onUpdateValue: setUpdatedValue,
		startWithEditViewOpen,
		updatedValue,
		clipboardComponentType: 'multiVersionSelect',
	});

	const renderReadView = useCallback(
		() => <MultiVersionPickerReadView fragmentRef={data} isTruncated={isTruncated} />,
		[data, isTruncated],
	);

	const getEditViewProps = (fieldProps: ValidationFieldProps): MultiVersionPickerEditViewProps => ({
		...fieldProps,
		autoFocus: true,
		fieldId: uniqueFieldId,
		fieldName,
		value: updatedValue,
		menuPosition,
		menuPortalTarget,
		onChange: handleChange,
		spacing,
	});

	return (
		<FieldInlineEditLiteWithEntryPoint
			editViewPopup={editViewPopup}
			editViewPopupAlignBlock={editViewPopupAlignBlock}
			editViewPopupMinWidth="small"
			editViewEntryPoint={MultiVersionPickerEditViewEntryPoint}
			editViewEntryPointParams={{}}
			getEditViewProps={getEditViewProps}
			fieldName={fieldName}
			hasUnsubmittedChanges={hasServerValidationError}
			invalidMessage={invalidMessage}
			isEditing={isEditing}
			isEditable={isFieldEditable}
			onCancel={handleCancel}
			onConfirm={handleConfirm}
			onEdit={handleEdit}
			onCopy={handleCopy}
			onCut={handleCut}
			onPaste={handlePaste}
			readViewFitContainerHeight={readViewFitContainerHeight}
			readView={renderReadView}
			hideActionButtons
		/>
	);
};

/**
 * Inline edit will handle the switching behaviour between the 'readView' and 'editView' multiVersionPicker.
 *
 * @param props [MultiVersionPickerInlineEditViewProps](./types.tsx)
 */
export const MultiVersionPickerInlineEditView = ({
	fragmentRef,
	...props
}: MultiVersionPickerInlineEditViewProps) => {
	/**
	 * This fragment should spread the inner InlineEditViewWithIsEditable fragment and read the isEditable flag from the
	 * fieldConfig.
	 */
	const data = useFragment<MultiVersionPickerFragment>(
		graphql`
			fragment multiVersionPicker_issueFieldMultiVersionPickerInlineEditFull_MultiVersionPickerInlineEditView_fragmentRef on JiraMultipleVersionPickerField {
				...multiVersionPicker_issueFieldMultiVersionPickerInlineEditFull_MultiVersionPickerInlineEditViewWithIsEditable_fragmentRef
				fieldConfig {
					isEditable
				}
			}
		`,
		fragmentRef,
	);

	return (
		<MultiVersionPickerInlineEditViewWithIsEditable
			{...props}
			fragmentRef={data}
			isEditable={data.fieldConfig?.isEditable ?? false}
		/>
	);
};
