/**
 * @generated SignedSource<<fee4ae937f04c57ced84ba4dd77aa6b8>>
 * @relayHash 5466773c0cfcf58c66d8a3bf60591c61
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID d9014e88eae41f81c536dc95368514e09b8e85555275b98213f1d725a3839d24

import type { ConcreteRequest, Mutation } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraMultiValueFieldOperations = "ADD" | "REMOVE" | "SET" | "%future added value";
export type JiraUpdateConfluenceRemoteIssueLinksFieldInput = {
  id: string;
  operations: ReadonlyArray<JiraConfluenceRemoteIssueLinksFieldOperationInput>;
};
export type JiraConfluenceRemoteIssueLinksFieldOperationInput = {
  links: ReadonlyArray<JiraConfluenceRemoteIssueLinkInput>;
  operation: JiraMultiValueFieldOperations;
};
export type JiraConfluenceRemoteIssueLinkInput = {
  href?: string | null | undefined;
  id?: string | null | undefined;
};
export type confluencePages_issueFieldConfluencePagesInlineEditFull_Mutation$variables = {
  input: JiraUpdateConfluenceRemoteIssueLinksFieldInput;
};
export type confluencePages_issueFieldConfluencePagesInlineEditFull_Mutation$data = {
  readonly jira: {
    readonly updateConfluenceRemoteIssueLinksField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly confluenceRemoteIssueLinks: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly href: string | null | undefined;
              readonly id: string;
            } | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined;
        readonly " $fragmentSpreads": FragmentRefs<"confluencePages_issueFieldConfluencePagesReadviewFull_ConfluencePagesReadView">;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type confluencePages_issueFieldConfluencePagesInlineEditFull_Mutation$rawResponse = {
  readonly jira: {
    readonly updateConfluenceRemoteIssueLinksField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly confluenceRemoteIssueLinks: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly href: string | null | undefined;
              readonly id: string;
            } | null | undefined;
          } | null | undefined> | null | undefined;
          readonly totalCount: number | null | undefined;
        } | null | undefined;
        readonly id: string;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type confluencePages_issueFieldConfluencePagesInlineEditFull_Mutation = {
  rawResponse: confluencePages_issueFieldConfluencePagesInlineEditFull_Mutation$rawResponse;
  response: confluencePages_issueFieldConfluencePagesInlineEditFull_Mutation$data;
  variables: confluencePages_issueFieldConfluencePagesInlineEditFull_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "success"
},
v3 = {
  "concreteType": "MutationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "message"
    }
  ]
},
v4 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000
  }
],
v5 = {
  "kind": "ScalarField",
  "name": "id"
},
v6 = {
  "concreteType": "JiraConfluenceRemoteIssueLinkEdge",
  "kind": "LinkedField",
  "name": "edges",
  "plural": true,
  "selections": [
    {
      "concreteType": "JiraConfluenceRemoteIssueLink",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        (v5/*: any*/),
        {
          "kind": "ScalarField",
          "name": "href"
        }
      ]
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "confluencePages_issueFieldConfluencePagesInlineEditFull_Mutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraUpdateConfluenceRemoteIssueLinksFieldPayload",
            "kind": "LinkedField",
            "name": "updateConfluenceRemoteIssueLinksField",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "concreteType": "JiraConfluenceRemoteIssueLinksField",
                "kind": "LinkedField",
                "name": "field",
                "plural": false,
                "selections": [
                  {
                    "kind": "FragmentSpread",
                    "name": "confluencePages_issueFieldConfluencePagesReadviewFull_ConfluencePagesReadView"
                  },
                  {
                    "args": (v4/*: any*/),
                    "concreteType": "JiraConfluenceRemoteIssueLinkConnection",
                    "kind": "LinkedField",
                    "name": "confluenceRemoteIssueLinks",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/)
                    ],
                    "storageKey": "confluenceRemoteIssueLinks(first:1000)"
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "confluencePages_issueFieldConfluencePagesInlineEditFull_Mutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraUpdateConfluenceRemoteIssueLinksFieldPayload",
            "kind": "LinkedField",
            "name": "updateConfluenceRemoteIssueLinksField",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "concreteType": "JiraConfluenceRemoteIssueLinksField",
                "kind": "LinkedField",
                "name": "field",
                "plural": false,
                "selections": [
                  {
                    "args": (v4/*: any*/),
                    "concreteType": "JiraConfluenceRemoteIssueLinkConnection",
                    "kind": "LinkedField",
                    "name": "confluenceRemoteIssueLinks",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      {
                        "kind": "ScalarField",
                        "name": "totalCount"
                      }
                    ],
                    "storageKey": "confluenceRemoteIssueLinks(first:1000)"
                  },
                  (v5/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "d9014e88eae41f81c536dc95368514e09b8e85555275b98213f1d725a3839d24",
    "metadata": {},
    "name": "confluencePages_issueFieldConfluencePagesInlineEditFull_Mutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "a9a457b54100bc9a68a78acaa2bbf10f";

export default node;
