import React, { memo, useMemo } from 'react';
import { graphql, useFragment } from 'react-relay';
import { COMPOUND_OPERATOR_AND, OPERATOR_EQUALS, creators, print } from '@atlaskit/jql-ast';
import { useIntl } from '@atlassian/jira-intl';
import ListCellWithPopup from '@atlassian/jira-list-with-popup/src/ui/main.tsx';
import {
	TagItem,
	TagItemPopupContentWrapper,
} from '@atlassian/jira-list-with-popup/src/ui/tag-item/index.tsx';
import { ComponentsInlineEditViewWithIsEditable } from '@atlassian/jira-issue-field-components-inline-edit-full/src/ui/components/index.tsx';
import type { components_nativeIssueTable_ComponentsInlineEditCell_fieldRef$key } from '@atlassian/jira-relay/src/__generated__/components_nativeIssueTable_ComponentsInlineEditCell_fieldRef.graphql';
import type { components_nativeIssueTable_ComponentsCell_fieldRef$key } from '@atlassian/jira-relay/src/__generated__/components_nativeIssueTable_ComponentsCell_fieldRef.graphql';
import { escapeBackslashes, isNonNullish } from '../../../utils/index.tsx';
import ErrorCell from '../../error-cell/index.tsx';
import { INLINE_EDIT_ANALYTICS_ATTRIBUTES } from '../common/inline-edit-analytics-attributes.tsx';
import { useLazyLoadEditability } from '../../../../services/lazy-editability-loader/index.tsx';
import {
	useIsInlineEditingExtendedFieldSupportEnabled,
	useIsSingleLineRowHeightEnabled,
} from '../../../../controllers/features/selectors.tsx';
import messages from './messages.tsx';

export type Props = {
	fieldRef: components_nativeIssueTable_ComponentsCell_fieldRef$key;
	issueSearchBaseUrl: string;
	jqlTerm: string;
	projectKey: string;
};

export type ComponentsInlineEditCellProps = {
	fieldRef: components_nativeIssueTable_ComponentsInlineEditCell_fieldRef$key;
	issueSearchBaseUrl: string;
};

/**
 * Expected JQL Output: `project = ${projectKey} AND ${jqlTerm} = ${name}`
 */
const getLinkJql = (projectKey: string, jqlTerm: string, name: string) => {
	const jast = creators.jast(
		creators.query(
			creators.compoundClause(creators.compoundOperator(COMPOUND_OPERATOR_AND), [
				creators.terminalClause(
					creators.field('project'),
					creators.operator(OPERATOR_EQUALS),
					creators.valueOperand(projectKey),
				),
				creators.terminalClause(
					creators.field(escapeBackslashes(jqlTerm)),
					creators.operator(OPERATOR_EQUALS),
					creators.valueOperand(escapeBackslashes(name)),
				),
			]),
		),
	);

	return print(jast, {
		operatorCase: 'upper',
	});
};

export const ComponentsCell = memo<Props>(
	({ fieldRef, issueSearchBaseUrl, jqlTerm, projectKey }: Props) => {
		const { formatMessage } = useIntl();
		const { componentsOld: selectedComponentsConnection } =
			useFragment<components_nativeIssueTable_ComponentsCell_fieldRef$key>(
				graphql`
					fragment components_nativeIssueTable_ComponentsCell_fieldRef on JiraComponentsField {
						componentsOld: selectedComponentsConnection {
							... on JiraComponentConnection {
								edges {
									node {
										name
									}
								}
							}
						}
					}
				`,
				fieldRef,
			);

		const components = selectedComponentsConnection?.edges;

		const filteredComponents = useMemo(
			() =>
				components
					?.map((edge) =>
						edge?.node?.name != null
							? {
									key: edge.node.name,
									name: edge.node.name,
									href: `${issueSearchBaseUrl}?jql=${encodeURIComponent(
										getLinkJql(projectKey, jqlTerm, edge.node.name),
									)}`,
								}
							: null,
					)
					.filter(isNonNullish) ?? [],
			[components, issueSearchBaseUrl, projectKey, jqlTerm],
		);

		if (!components || components.length !== filteredComponents.length || !projectKey) {
			return <ErrorCell cellType="components" reason="Missing required fields" />;
		}

		return (
			<ListCellWithPopup
				items={filteredComponents}
				ItemComponent={TagItem}
				PopupContentWrapper={TagItemPopupContentWrapper}
				showMoreTooltip={formatMessage(messages.triggerButton)}
			/>
		);
	},
);

export const ComponentsInlineEditCell = ({
	fieldRef,
	issueSearchBaseUrl,
}: ComponentsInlineEditCellProps) => {
	const data = useFragment<components_nativeIssueTable_ComponentsInlineEditCell_fieldRef$key>(
		graphql`
			fragment components_nativeIssueTable_ComponentsInlineEditCell_fieldRef on JiraComponentsField
			@argumentDefinitions(isInlineEditingEnabled: { type: "Boolean!" }) {
				id
				lazyIsEditableInIssueView @include(if: $isInlineEditingEnabled)
				...components_issueFieldComponentsInlineEditFull_ComponentsInlineEditViewWithIsEditable_fragmentRef
			}
		`,
		fieldRef,
	);

	const isInlineEditingExtendedFieldSupportEnabled =
		useIsInlineEditingExtendedFieldSupportEnabled();
	const isSingleLineRowHeightEnabled = useIsSingleLineRowHeightEnabled();
	// If the property does not exist in fragment data then we know it has been conditionally excluded because inline
	// editing is disabled. In this case we explicitly set isEditable to false to bypass the lazy editability query.
	const isEditable =
		'lazyIsEditableInIssueView' in data && isInlineEditingExtendedFieldSupportEnabled
			? data.lazyIsEditableInIssueView
			: false;
	useLazyLoadEditability(data.id, isEditable);

	return (
		<ComponentsInlineEditViewWithIsEditable
			attributes={INLINE_EDIT_ANALYTICS_ATTRIBUTES}
			spacing="default"
			baseSearchUrl={issueSearchBaseUrl}
			editViewPopup
			editViewPopupAlignBlock="center"
			fragmentRef={data}
			isTruncated
			isEditable={isEditable ?? false}
			menuPosition="fixed"
			readViewFitContainerHeight
			isTruncatedWithHoverPopover={isSingleLineRowHeightEnabled}
		/>
	);
};
