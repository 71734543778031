import { useCallback } from 'react';
import { fetchQuery, graphql } from 'react-relay';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import getRelayEnvironment from '@atlassian/jira-relay-environment/src/index.tsx';
import type {
	realtimeIssueDetails_Query$data as IssueDetailsResponse,
	realtimeIssueDetails_Query as IssueDetails,
} from '@atlassian/jira-relay/src/__generated__/realtimeIssueDetails_Query.graphql.ts';
import { PACKAGE_NAME, TEAM_NAME } from '../../common/constants.tsx';
import type { IssueSearchInputType, SearchViewContextInputType } from './types.tsx';

export const useGetRealtimeIssueDetails = () => {
	return useCallback(
		async ({
			keys,
			cloudId,
			fieldSetIds,
			isInlineEditingEnabled,
			isDensityFull,
			isHierarchyEnabled,
			isGroupingEnabled,
			issueSearchInput,
			searchViewContextInput,
			projectKey,
			isStatusTrackingEnabled,
		}: {
			keys: string[];
			cloudId: string;
			fieldSetIds: string[];
			isInlineEditingEnabled: boolean;
			isDensityFull: boolean;
			isHierarchyEnabled: boolean | undefined;
			isGroupingEnabled: boolean;
			issueSearchInput: IssueSearchInputType;
			searchViewContextInput: SearchViewContextInputType;
			projectKey: string;
			isStatusTrackingEnabled: boolean | undefined;
		}): Promise<IssueDetailsResponse | undefined> => {
			const environment = getRelayEnvironment();

			try {
				// if the issue is in a loaded context, the issue is visible so we need to fetch the fields for display
				return await fetchQuery<IssueDetails>(
					environment,
					graphql`
						query realtimeIssueDetails_Query(
							$cloudId: ID!
							$keys: [String!]!
							$fieldSetIds: [String!]!
							$isInlineEditingEnabled: Boolean!
							$isDensityFull: Boolean!
							$isHierarchyEnabled: Boolean!
							$isGroupingEnabled: Boolean
							$issueSearchInput: JiraIssueSearchInput!
							$searchViewContextInput: JiraIssueSearchViewContextInput!
							$projectKey: String!
							$isStatusTrackingEnabled: Boolean!
						) {
							jira {
								# We make a deferred request for issues here, as we need to throttle requests for issue details from the subscriptions.
								# We leave fields unused here as we need them to populate the store, but not necessarily use them in the controller.
								# eslint-disable-next-line @atlassian/relay/unused-fields
								issuesByKey(cloudId: $cloudId, keys: $keys) {
									__id
									issueId
									key
									parentIssueField {
										parentIssue {
											__id
										}
									}
									issueTypeField @include(if: $isHierarchyEnabled) {
										id
										issueType {
											hierarchy {
												level
											}
										}
									}
									statusField @include(if: $isStatusTrackingEnabled) {
										status {
											statusCategory {
												statusCategoryId
											}
										}
									}
									fieldSetsById(fieldSetIds: $fieldSetIds, first: 500) {
										__id
										# We need to ensure all updated fields data is fetched, so we reuse the fragment spread from the issue row component.
										# eslint-disable-next-line @atlassian/relay/must-colocate-fragment-spreads
										...issueRow_nativeIssueTable_IssueRowWithFragments_fieldSets
											@arguments(
												isInlineEditingEnabled: $isInlineEditingEnabled
												isDensityFull: $isDensityFull
											)
									}
									canHaveChildIssues(projectKey: $projectKey) @include(if: $isHierarchyEnabled)
									searchViewContext(
										isHierarchyEnabled: $isHierarchyEnabled
										isGroupingEnabled: $isGroupingEnabled
										issueSearchInput: $issueSearchInput
										searchViewContextInput: $searchViewContextInput
									) @optIn(to: "JiraListComponent-M1.2") {
										contexts {
											afterIssueId
											beforeIssueId
											position
											... on JiraIssueSearchViewContextMappingByParent {
												parentIssueId
											}
											... on JiraIssueSearchViewContextMappingByGroup {
												jql
											}
										}
									}
								}
							}
						}
					`,
					{
						keys,
						cloudId,
						fieldSetIds,
						isInlineEditingEnabled,
						isDensityFull,
						issueSearchInput,
						searchViewContextInput,
						isHierarchyEnabled: isHierarchyEnabled ?? false,
						isStatusTrackingEnabled: isStatusTrackingEnabled ?? false,
						isGroupingEnabled,
						projectKey,
					},
					{ fetchPolicy: 'network-only' },
				).toPromise();
			} catch (error) {
				fireErrorAnalytics({
					meta: {
						id: 'realTimeFetchIssueFieldSetConnection',
						packageName: PACKAGE_NAME,
						teamName: TEAM_NAME,
					},
					error: error instanceof Error ? error : undefined,
					sendToPrivacyUnsafeSplunk: true,
				});

				return Promise.resolve(undefined);
			}
		},
		[],
	);
};
