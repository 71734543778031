/**
 * @generated SignedSource<<3e00a6dfe9714fdf04094d9884c9fdcc>>
 * @relayHash e9d190d504b2b778d0aa9d155628ab9e
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 76b36d4f04b088c75341e092893164edb9a67160b7d70f7728305d97a183b376

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraSoftwareIssueSearchCustomInputContext = "BACKLOG" | "BOARD" | "NONE" | "%future added value";
export type JiraIssueSearchViewFieldSetsContext = {
  issueContext?: JiraIssueSearchViewFieldSetsIssueContext | null | undefined;
  projectContext?: JiraIssueSearchViewFieldSetsProjectContext | null | undefined;
};
export type JiraIssueSearchViewFieldSetsIssueContext = {
  issueKey?: string | null | undefined;
};
export type JiraIssueSearchViewFieldSetsProjectContext = {
  issueType?: string | null | undefined;
  project?: string | null | undefined;
};
export type JiraIssueSearchFieldSetsInput = {
  fieldSetIds?: ReadonlyArray<string> | null | undefined;
  viewInput?: JiraIssueSearchViewInput | null | undefined;
};
export type JiraIssueSearchViewInput = {
  context?: JiraIssueSearchViewFieldSetsContext | null | undefined;
  filterId?: string | null | undefined;
  namespace?: string | null | undefined;
  viewId?: string | null | undefined;
};
export type JiraIssueSearchInput = {
  childIssuesInput?: JiraIssueSearchChildIssuesInput | null | undefined;
  customInput?: JiraIssueSearchCustomInput | null | undefined;
  filterId?: string | null | undefined;
  jql?: string | null | undefined;
};
export type JiraIssueSearchChildIssuesInput = {
  filterByProjectKeys?: ReadonlyArray<string> | null | undefined;
  filterId?: string | null | undefined;
  jql?: string | null | undefined;
  namespace?: string | null | undefined;
  parentIssueKey: string;
  viewId?: string | null | undefined;
};
export type JiraIssueSearchCustomInput = {
  jiraSoftwareInput?: JiraSoftwareIssueSearchCustomInput | null | undefined;
};
export type JiraSoftwareIssueSearchCustomInput = {
  additionalJql?: string | null | undefined;
  context?: JiraSoftwareIssueSearchCustomInputContext | null | undefined;
  jiraEntityId: string;
};
export type JiraIssueSearchOptions = {
  issueKey?: string | null | undefined;
};
export type JiraIssueSearchViewConfigInput = {
  staticViewInput?: JiraIssueSearchStaticViewInput | null | undefined;
  viewInput?: JiraIssueSearchViewInput | null | undefined;
};
export type JiraIssueSearchStaticViewInput = {
  isGroupingEnabled?: boolean | null | undefined;
  isHideDoneEnabled?: boolean | null | undefined;
  isHierarchyEnabled?: boolean | null | undefined;
};
export type tableBody_issueTableHierarchy_childIssuesPaginationQuery$variables = {
  after?: string | null | undefined;
  amountOfColumns?: number | null | undefined;
  before?: string | null | undefined;
  cloudId: string;
  fieldSetIds: ReadonlyArray<string>;
  fieldSetsContext?: JiraIssueSearchViewFieldSetsContext | null | undefined;
  fieldSetsInput?: JiraIssueSearchFieldSetsInput | null | undefined;
  filterId?: string | null | undefined;
  first?: number | null | undefined;
  isDensityFull?: boolean | null | undefined;
  isInlineEditingEnabled?: boolean | null | undefined;
  isReparentingEnabled?: boolean | null | undefined;
  issueSearchInput: JiraIssueSearchInput;
  last?: number | null | undefined;
  namespace?: string | null | undefined;
  options?: JiraIssueSearchOptions | null | undefined;
  projectKey?: string | null | undefined;
  projectKeys?: ReadonlyArray<string> | null | undefined;
  shouldQueryFieldSetsById: boolean;
  shouldQueryHasChildren: boolean;
  viewConfigInput?: JiraIssueSearchViewConfigInput | null | undefined;
  viewId?: string | null | undefined;
};
export type tableBody_issueTableHierarchy_childIssuesPaginationQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"childRowsPagination_issueTableHierarchy_query">;
};
export type tableBody_issueTableHierarchy_childIssuesPaginationQuery = {
  response: tableBody_issueTableHierarchy_childIssuesPaginationQuery$data;
  variables: tableBody_issueTableHierarchy_childIssuesPaginationQuery$variables;
};
({
  "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider": isJscInfiniteScrollEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider": isJscM2Enabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider": isNikeChinStoryPointsInlineEditEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider": isJscInlineEditRefactorEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone2experimentrelayprovider": includeSingleSelectColorField_provider
} as {
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone2experimentrelayprovider: {
    readonly get: () => boolean;
  };
});

import isJscInfiniteScrollEnabled_provider from '@atlassian/jira-relay-provider/src/is-jsc-infinite-scroll-enabled.relayprovider';
import isJscInlineEditRefactorEnabled_provider from '@atlassian/jira-relay-provider/src/jsc-inline-editing-field-refactor.relayprovider';
import isJscM2Enabled_provider from '@atlassian/jira-relay-provider/src/jsc-m2-fe-changes.relayprovider';
import isNikeChinStoryPointsInlineEditEnabled_provider from '@atlassian/jira-relay-provider/src/nike-chin-story-points-inline-edit.relayprovider';
import includeSingleSelectColorField_provider from '@atlassian/jira-relay-provider/src/thor-colorful-single-select-milestone2-experiment.relayprovider';

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "amountOfColumns"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "before"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fieldSetIds"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fieldSetsContext"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fieldSetsInput"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filterId"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v9 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "isDensityFull"
},
v10 = {
  "defaultValue": true,
  "kind": "LocalArgument",
  "name": "isInlineEditingEnabled"
},
v11 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "isReparentingEnabled"
},
v12 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueSearchInput"
},
v13 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v14 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "namespace"
},
v15 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "options"
},
v16 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectKey"
},
v17 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectKeys"
},
v18 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "shouldQueryFieldSetsById"
},
v19 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "shouldQueryHasChildren"
},
v20 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "viewConfigInput"
},
v21 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "viewId"
},
v22 = {
  "kind": "Variable",
  "name": "projectKey",
  "variableName": "projectKey"
},
v23 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "before",
    "variableName": "before"
  },
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  },
  {
    "kind": "Variable",
    "name": "fieldSetsInput",
    "variableName": "fieldSetsInput"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "issueSearchInput",
    "variableName": "issueSearchInput"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  },
  {
    "kind": "Variable",
    "name": "options",
    "variableName": "options"
  },
  {
    "kind": "Variable",
    "name": "viewConfigInput",
    "variableName": "viewConfigInput"
  }
],
v24 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v25 = {
  "kind": "ScalarField",
  "name": "cursor"
},
v26 = {
  "kind": "ScalarField",
  "name": "id"
},
v27 = {
  "kind": "ScalarField",
  "name": "key"
},
v28 = {
  "kind": "ScalarField",
  "name": "issueId"
},
v29 = {
  "kind": "ScalarField",
  "name": "fieldId"
},
v30 = {
  "kind": "ScalarField",
  "name": "statusCategoryId"
},
v31 = {
  "concreteType": "JiraStatusCategory",
  "kind": "LinkedField",
  "name": "statusCategory",
  "plural": false,
  "selections": [
    (v30/*: any*/),
    (v26/*: any*/)
  ]
},
v32 = {
  "kind": "InlineFragment",
  "selections": [
    (v29/*: any*/),
    {
      "concreteType": "JiraStatus",
      "kind": "LinkedField",
      "name": "status",
      "plural": false,
      "selections": [
        (v31/*: any*/),
        (v26/*: any*/)
      ]
    }
  ],
  "type": "JiraStatusField"
},
v33 = {
  "concreteType": "JiraIssueTypeHierarchyLevel",
  "kind": "LinkedField",
  "name": "hierarchy",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "level"
    }
  ]
},
v34 = {
  "kind": "ScalarField",
  "name": "__id"
},
v35 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "amountOfColumns"
},
v36 = {
  "kind": "ScalarField",
  "name": "type"
},
v37 = {
  "kind": "ScalarField",
  "name": "name"
},
v38 = {
  "kind": "ScalarField",
  "name": "value"
},
v39 = [
  (v38/*: any*/),
  (v26/*: any*/),
  {
    "kind": "ScalarField",
    "name": "isDisabled"
  }
],
v40 = {
  "condition": "isInlineEditingEnabled",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "kind": "ScalarField",
          "name": "lazyIsEditableInIssueView"
        }
      ]
    }
  ]
},
v41 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000
  }
],
v42 = {
  "alias": "value",
  "kind": "ScalarField",
  "name": "id"
},
v43 = {
  "alias": "label",
  "kind": "ScalarField",
  "name": "name"
},
v44 = {
  "kind": "ScalarField",
  "name": "dateTime"
},
v45 = {
  "kind": "ScalarField",
  "name": "text"
},
v46 = {
  "kind": "InlineFragment",
  "selections": [
    (v45/*: any*/),
    (v37/*: any*/),
    (v40/*: any*/)
  ],
  "type": "JiraSingleLineTextField"
},
v47 = {
  "kind": "ScalarField",
  "name": "totalCount"
},
v48 = {
  "concreteType": "JiraColor",
  "kind": "LinkedField",
  "name": "color",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "colorKey"
    },
    (v26/*: any*/)
  ]
},
v49 = [
  (v48/*: any*/)
],
v50 = {
  "kind": "ScalarField",
  "name": "optionId"
},
v51 = {
  "kind": "ScalarField",
  "name": "accountId"
},
v52 = {
  "kind": "ScalarField",
  "name": "picture"
},
v53 = {
  "kind": "ScalarField",
  "name": "accountStatus"
},
v54 = {
  "kind": "ScalarField",
  "name": "endCursor"
},
v55 = {
  "kind": "ScalarField",
  "name": "hasNextPage"
},
v56 = [
  (v37/*: any*/),
  (v26/*: any*/)
],
v57 = {
  "concreteType": "JiraFieldConfig",
  "kind": "LinkedField",
  "name": "fieldConfig",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "isRequired"
    }
  ]
},
v58 = {
  "kind": "ScalarField",
  "name": "summary"
},
v59 = {
  "concreteType": "JiraAvatar",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "small"
    }
  ]
},
v60 = {
  "concreteType": "JiraSingleLineTextField",
  "kind": "LinkedField",
  "name": "summaryField",
  "plural": false,
  "selections": [
    (v45/*: any*/),
    (v26/*: any*/)
  ]
},
v61 = {
  "concreteType": "JiraColorField",
  "kind": "LinkedField",
  "name": "issueColorField",
  "plural": false,
  "selections": [
    (v48/*: any*/),
    (v26/*: any*/)
  ]
},
v62 = {
  "concreteType": "JiraAvatar",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "xsmall"
    }
  ]
},
v63 = {
  "concreteType": "JiraIssueTypeField",
  "kind": "LinkedField",
  "name": "issueTypeField",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraIssueType",
      "kind": "LinkedField",
      "name": "issueType",
      "plural": false,
      "selections": [
        (v62/*: any*/),
        (v37/*: any*/),
        (v26/*: any*/)
      ]
    },
    (v26/*: any*/)
  ]
},
v64 = {
  "kind": "ScalarField",
  "name": "webUrl"
},
v65 = {
  "kind": "ScalarField",
  "name": "number"
},
v66 = [
  {
    "concreteType": "JiraIssueFieldSetEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "concreteType": "JiraIssueFieldSet",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v36/*: any*/),
          {
            "kind": "ScalarField",
            "name": "fieldSetId"
          },
          {
            "concreteType": "JiraIssueFieldConnection",
            "kind": "LinkedField",
            "name": "fields",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraIssueFieldEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v24/*: any*/),
                      (v29/*: any*/),
                      (v36/*: any*/),
                      {
                        "kind": "TypeDiscriminator",
                        "abstractKey": "__isJiraIssueField"
                      },
                      (v26/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "JiraAffectedServiceConnection",
                            "kind": "LinkedField",
                            "name": "selectedAffectedServicesConnection",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "JiraAffectedServiceEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "concreteType": "JiraAffectedService",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "kind": "ScalarField",
                                        "name": "serviceId"
                                      },
                                      (v37/*: any*/),
                                      (v26/*: any*/)
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ],
                        "type": "JiraAffectedServicesField"
                      },
                      {
                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider",
                        "kind": "Condition",
                        "passingValue": true,
                        "selections": [
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v37/*: any*/),
                              {
                                "concreteType": "JiraCascadingOption",
                                "kind": "LinkedField",
                                "name": "cascadingOption",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraOption",
                                    "kind": "LinkedField",
                                    "name": "parentOptionValue",
                                    "plural": false,
                                    "selections": (v39/*: any*/)
                                  },
                                  {
                                    "concreteType": "JiraOption",
                                    "kind": "LinkedField",
                                    "name": "childOptionValue",
                                    "plural": false,
                                    "selections": (v39/*: any*/)
                                  }
                                ]
                              },
                              (v40/*: any*/)
                            ],
                            "type": "JiraCascadingSelectField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v37/*: any*/),
                              {
                                "args": (v41/*: any*/),
                                "concreteType": "JiraComponentConnection",
                                "kind": "LinkedField",
                                "name": "selectedComponentsConnection",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraComponentEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "concreteType": "JiraComponent",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v42/*: any*/),
                                          (v43/*: any*/),
                                          (v26/*: any*/)
                                        ]
                                      }
                                    ]
                                  }
                                ],
                                "storageKey": "selectedComponentsConnection(first:1000)"
                              },
                              (v40/*: any*/)
                            ],
                            "type": "JiraComponentsField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v44/*: any*/),
                              (v37/*: any*/),
                              (v40/*: any*/)
                            ],
                            "type": "JiraDateTimePickerField"
                          },
                          (v46/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "concreteType": "JiraCommentSummary",
                                "kind": "LinkedField",
                                "name": "commentSummary",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "ScalarField",
                                    "name": "canAddComment"
                                  },
                                  (v47/*: any*/)
                                ]
                              }
                            ],
                            "type": "JiraCommentSummaryField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v37/*: any*/),
                              {
                                "kind": "ScalarField",
                                "name": "uri"
                              },
                              (v40/*: any*/)
                            ],
                            "type": "JiraUrlField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v37/*: any*/),
                              {
                                "concreteType": "JiraOptionConnection",
                                "kind": "LinkedField",
                                "name": "fieldOptions",
                                "plural": false,
                                "selections": [
                                  (v47/*: any*/)
                                ]
                              },
                              {
                                "concreteType": "JiraOption",
                                "kind": "LinkedField",
                                "name": "fieldOption",
                                "plural": false,
                                "selections": [
                                  (v38/*: any*/),
                                  {
                                    "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider",
                                    "kind": "Condition",
                                    "passingValue": true,
                                    "selections": (v49/*: any*/)
                                  },
                                  {
                                    "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone2experimentrelayprovider",
                                    "kind": "Condition",
                                    "passingValue": true,
                                    "selections": (v49/*: any*/)
                                  },
                                  (v26/*: any*/),
                                  (v50/*: any*/)
                                ]
                              },
                              (v40/*: any*/)
                            ],
                            "type": "JiraSingleSelectField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v37/*: any*/),
                              {
                                "concreteType": "JiraOptionConnection",
                                "kind": "LinkedField",
                                "name": "selectedOptions",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraOptionEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "concreteType": "JiraOption",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v26/*: any*/),
                                          (v38/*: any*/),
                                          (v50/*: any*/)
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              },
                              (v40/*: any*/)
                            ],
                            "type": "JiraMultipleSelectField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v37/*: any*/),
                              {
                                "concreteType": "JiraOptionConnection",
                                "kind": "LinkedField",
                                "name": "selectedOptions",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraOptionEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "concreteType": "JiraOption",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v26/*: any*/),
                                          (v38/*: any*/)
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              },
                              (v40/*: any*/)
                            ],
                            "type": "JiraCheckboxesField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v37/*: any*/),
                              {
                                "concreteType": "JiraUserConnection",
                                "kind": "LinkedField",
                                "name": "selectedUsersConnection",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraUserEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v24/*: any*/),
                                          (v51/*: any*/),
                                          (v37/*: any*/),
                                          (v52/*: any*/),
                                          (v26/*: any*/),
                                          (v53/*: any*/),
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              {
                                                "kind": "ScalarField",
                                                "name": "email"
                                              }
                                            ],
                                            "type": "AtlassianAccountUser"
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              },
                              (v40/*: any*/)
                            ],
                            "type": "JiraMultipleSelectUserPickerField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v37/*: any*/),
                              {
                                "concreteType": "JiraTeamView",
                                "kind": "LinkedField",
                                "name": "selectedTeam",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "ScalarField",
                                    "name": "jiraSuppliedId"
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "name": "jiraSuppliedName"
                                  }
                                ]
                              },
                              (v40/*: any*/)
                            ],
                            "type": "JiraTeamViewField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "concreteType": "JiraIssue",
                                "kind": "LinkedField",
                                "name": "issue",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraTimeTrackingField",
                                    "kind": "LinkedField",
                                    "name": "timeTrackingField",
                                    "plural": false,
                                    "selections": [
                                      (v26/*: any*/),
                                      (v40/*: any*/),
                                      {
                                        "concreteType": "JiraEstimate",
                                        "kind": "LinkedField",
                                        "name": "originalEstimate",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "kind": "ScalarField",
                                            "name": "timeInSeconds"
                                          }
                                        ]
                                      },
                                      {
                                        "concreteType": "JiraTimeTrackingSettings",
                                        "kind": "LinkedField",
                                        "name": "timeTrackingSettings",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "kind": "ScalarField",
                                            "name": "workingHoursPerDay"
                                          },
                                          {
                                            "kind": "ScalarField",
                                            "name": "workingDaysPerWeek"
                                          },
                                          {
                                            "kind": "ScalarField",
                                            "name": "defaultFormat"
                                          },
                                          {
                                            "kind": "ScalarField",
                                            "name": "defaultUnit"
                                          }
                                        ]
                                      },
                                      (v29/*: any*/),
                                      (v37/*: any*/),
                                      (v36/*: any*/)
                                    ]
                                  },
                                  (v26/*: any*/)
                                ]
                              }
                            ],
                            "type": "JiraOriginalTimeEstimateField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v37/*: any*/),
                              {
                                "concreteType": "JiraOption",
                                "kind": "LinkedField",
                                "name": "selectedOption",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": "label",
                                    "kind": "ScalarField",
                                    "name": "value"
                                  },
                                  (v26/*: any*/),
                                  (v42/*: any*/)
                                ]
                              },
                              (v40/*: any*/)
                            ],
                            "type": "JiraRadioSelectField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v37/*: any*/),
                              {
                                "args": (v41/*: any*/),
                                "concreteType": "JiraVersionConnection",
                                "kind": "LinkedField",
                                "name": "selectedVersionsConnection",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraVersionEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "concreteType": "JiraVersion",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v26/*: any*/),
                                          (v37/*: any*/),
                                          (v42/*: any*/),
                                          (v43/*: any*/),
                                          {
                                            "kind": "ScalarField",
                                            "name": "versionId"
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ],
                                "storageKey": "selectedVersionsConnection(first:1000)"
                              },
                              (v40/*: any*/)
                            ],
                            "type": "JiraMultipleVersionPickerField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "args": (v41/*: any*/),
                                "concreteType": "JiraGoalConnection",
                                "kind": "LinkedField",
                                "name": "selectedGoals",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraGoalEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "concreteType": "JiraGoal",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v26/*: any*/),
                                          (v27/*: any*/),
                                          (v24/*: any*/)
                                        ]
                                      },
                                      (v25/*: any*/)
                                    ]
                                  },
                                  {
                                    "concreteType": "PageInfo",
                                    "kind": "LinkedField",
                                    "name": "pageInfo",
                                    "plural": false,
                                    "selections": [
                                      (v54/*: any*/),
                                      (v55/*: any*/)
                                    ]
                                  }
                                ],
                                "storageKey": "selectedGoals(first:1000)"
                              },
                              {
                                "args": (v41/*: any*/),
                                "handle": "connection",
                                "key": "Goals_selectedGoals",
                                "kind": "LinkedHandle",
                                "name": "selectedGoals"
                              }
                            ],
                            "type": "JiraGoalsField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v37/*: any*/),
                              {
                                "args": (v41/*: any*/),
                                "concreteType": "JiraConfluenceRemoteIssueLinkConnection",
                                "kind": "LinkedField",
                                "name": "confluenceRemoteIssueLinks",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraConfluenceRemoteIssueLinkEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "concreteType": "JiraConfluenceRemoteIssueLink",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v26/*: any*/),
                                          {
                                            "kind": "ScalarField",
                                            "name": "href"
                                          }
                                        ]
                                      }
                                    ]
                                  },
                                  (v47/*: any*/)
                                ],
                                "storageKey": "confluenceRemoteIssueLinks(first:1000)"
                              },
                              (v40/*: any*/)
                            ],
                            "type": "JiraConfluenceRemoteIssueLinksField"
                          }
                        ]
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "JiraCmdbObjectConnection",
                            "kind": "LinkedField",
                            "name": "selectedCmdbObjectsConnection",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "JiraCmdbObjectEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "concreteType": "JiraCmdbObject",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "kind": "ScalarField",
                                        "name": "objectId"
                                      },
                                      {
                                        "kind": "ScalarField",
                                        "name": "label"
                                      },
                                      {
                                        "concreteType": "JiraCmdbAvatar",
                                        "kind": "LinkedField",
                                        "name": "avatar",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "concreteType": "JiraCmdbMediaClientConfig",
                                            "kind": "LinkedField",
                                            "name": "mediaClientConfig",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "kind": "ScalarField",
                                                "name": "clientId"
                                              },
                                              {
                                                "kind": "ScalarField",
                                                "name": "fileId"
                                              },
                                              {
                                                "kind": "ScalarField",
                                                "name": "mediaBaseUrl"
                                              },
                                              {
                                                "kind": "ScalarField",
                                                "name": "mediaJwtToken"
                                              }
                                            ]
                                          },
                                          {
                                            "kind": "ScalarField",
                                            "name": "url48"
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              },
                              {
                                "concreteType": "QueryError",
                                "kind": "LinkedField",
                                "name": "errors",
                                "plural": true,
                                "selections": [
                                  {
                                    "kind": "ScalarField",
                                    "name": "message"
                                  }
                                ]
                              }
                            ]
                          }
                        ],
                        "type": "JiraCMDBField"
                      },
                      {
                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider",
                        "kind": "Condition",
                        "passingValue": false,
                        "selections": [
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": "componentsOld",
                                "concreteType": "JiraComponentConnection",
                                "kind": "LinkedField",
                                "name": "selectedComponentsConnection",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraComponentEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "concreteType": "JiraComponent",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": (v56/*: any*/)
                                      }
                                    ]
                                  }
                                ]
                              }
                            ],
                            "type": "JiraComponentsField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v45/*: any*/)
                            ],
                            "type": "JiraSingleLineTextField"
                          }
                        ]
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "date"
                          },
                          (v37/*: any*/),
                          (v57/*: any*/),
                          (v40/*: any*/)
                        ],
                        "type": "JiraDatePickerField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v44/*: any*/)
                        ],
                        "type": "JiraDateTimePickerField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "JiraEpic",
                            "kind": "LinkedField",
                            "name": "epic",
                            "plural": false,
                            "selections": [
                              (v27/*: any*/),
                              (v58/*: any*/),
                              {
                                "kind": "ScalarField",
                                "name": "color"
                              },
                              (v26/*: any*/)
                            ]
                          }
                        ],
                        "type": "JiraEpicLinkField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "renderedFieldHtml"
                          }
                        ],
                        "type": "JiraFallbackField"
                      },
                      (v46/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "JiraStatusCategory",
                            "kind": "LinkedField",
                            "name": "statusCategory",
                            "plural": false,
                            "selections": [
                              (v37/*: any*/),
                              (v30/*: any*/),
                              (v26/*: any*/)
                            ]
                          }
                        ],
                        "type": "JiraStatusCategoryField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "JiraStatus",
                            "kind": "LinkedField",
                            "name": "status",
                            "plural": false,
                            "selections": [
                              (v37/*: any*/),
                              {
                                "kind": "ScalarField",
                                "name": "statusId"
                              },
                              (v31/*: any*/),
                              (v26/*: any*/)
                            ]
                          }
                        ],
                        "type": "JiraStatusField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "args": (v41/*: any*/),
                            "concreteType": "JiraLabelConnection",
                            "kind": "LinkedField",
                            "name": "selectedLabelsConnection",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "JiraLabelEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "concreteType": "JiraLabel",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v37/*: any*/)
                                    ]
                                  }
                                ]
                              },
                              (v47/*: any*/)
                            ],
                            "storageKey": "selectedLabelsConnection(first:1000)"
                          },
                          (v37/*: any*/),
                          (v40/*: any*/)
                        ],
                        "type": "JiraLabelsField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "JiraIssueType",
                            "kind": "LinkedField",
                            "name": "issueType",
                            "plural": false,
                            "selections": [
                              (v59/*: any*/),
                              (v37/*: any*/),
                              (v26/*: any*/)
                            ]
                          }
                        ],
                        "type": "JiraIssueTypeField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "JiraIssue",
                            "kind": "LinkedField",
                            "name": "parentIssue",
                            "plural": false,
                            "selections": [
                              (v27/*: any*/),
                              (v60/*: any*/),
                              (v26/*: any*/)
                            ]
                          },
                          {
                            "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider",
                            "kind": "Condition",
                            "passingValue": false,
                            "selections": [
                              {
                                "concreteType": "JiraIssue",
                                "kind": "LinkedField",
                                "name": "parentIssue",
                                "plural": false,
                                "selections": [
                                  (v27/*: any*/),
                                  (v61/*: any*/),
                                  (v63/*: any*/),
                                  (v60/*: any*/),
                                  (v26/*: any*/)
                                ]
                              }
                            ]
                          },
                          {
                            "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider",
                            "kind": "Condition",
                            "passingValue": true,
                            "selections": [
                              {
                                "concreteType": "JiraIssue",
                                "kind": "LinkedField",
                                "name": "parentIssue",
                                "plural": false,
                                "selections": [
                                  (v27/*: any*/),
                                  (v61/*: any*/),
                                  (v63/*: any*/),
                                  (v60/*: any*/),
                                  (v26/*: any*/),
                                  (v28/*: any*/),
                                  (v64/*: any*/),
                                  {
                                    "args": [
                                      {
                                        "kind": "Literal",
                                        "name": "ids",
                                        "value": [
                                          "summary",
                                          "issuetype",
                                          "status"
                                        ]
                                      }
                                    ],
                                    "concreteType": "JiraIssueFieldConnection",
                                    "kind": "LinkedField",
                                    "name": "fieldsById",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "concreteType": "JiraIssueFieldEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                              (v24/*: any*/),
                                              (v26/*: any*/),
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  (v29/*: any*/),
                                                  (v45/*: any*/)
                                                ],
                                                "type": "JiraSingleLineTextField"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  (v29/*: any*/),
                                                  {
                                                    "concreteType": "JiraIssueType",
                                                    "kind": "LinkedField",
                                                    "name": "issueType",
                                                    "plural": false,
                                                    "selections": [
                                                      {
                                                        "kind": "ScalarField",
                                                        "name": "issueTypeId"
                                                      },
                                                      (v37/*: any*/),
                                                      (v62/*: any*/),
                                                      (v33/*: any*/),
                                                      (v26/*: any*/)
                                                    ]
                                                  }
                                                ],
                                                "type": "JiraIssueTypeField"
                                              },
                                              (v32/*: any*/)
                                            ]
                                          }
                                        ]
                                      }
                                    ],
                                    "storageKey": "fieldsById(ids:[\"summary\",\"issuetype\",\"status\"])"
                                  }
                                ]
                              },
                              (v37/*: any*/),
                              (v40/*: any*/)
                            ]
                          }
                        ],
                        "type": "JiraParentIssueField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "JiraPriority",
                            "kind": "LinkedField",
                            "name": "priority",
                            "plural": false,
                            "selections": [
                              (v37/*: any*/),
                              {
                                "kind": "ScalarField",
                                "name": "iconUrl"
                              },
                              (v26/*: any*/)
                            ]
                          },
                          (v37/*: any*/),
                          (v40/*: any*/)
                        ],
                        "type": "JiraPriorityField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "JiraProject",
                            "kind": "LinkedField",
                            "name": "project",
                            "plural": false,
                            "selections": [
                              (v37/*: any*/),
                              (v27/*: any*/),
                              {
                                "concreteType": "JiraAvatar",
                                "kind": "LinkedField",
                                "name": "avatar",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "ScalarField",
                                    "name": "large"
                                  }
                                ]
                              },
                              (v26/*: any*/)
                            ]
                          }
                        ],
                        "type": "JiraProjectField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "JiraResolution",
                            "kind": "LinkedField",
                            "name": "resolution",
                            "plural": false,
                            "selections": (v56/*: any*/)
                          }
                        ],
                        "type": "JiraResolutionField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "JiraSprintConnection",
                            "kind": "LinkedField",
                            "name": "selectedSprintsConnection",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "JiraSprintEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "concreteType": "JiraSprint",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v26/*: any*/),
                                      {
                                        "kind": "ScalarField",
                                        "name": "sprintId"
                                      },
                                      (v37/*: any*/),
                                      {
                                        "kind": "ScalarField",
                                        "name": "state"
                                      },
                                      {
                                        "kind": "ScalarField",
                                        "name": "endDate"
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          },
                          (v37/*: any*/),
                          (v40/*: any*/)
                        ],
                        "type": "JiraSprintField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                              (v24/*: any*/),
                              (v51/*: any*/),
                              (v52/*: any*/),
                              (v37/*: any*/),
                              (v26/*: any*/),
                              (v53/*: any*/)
                            ]
                          },
                          (v37/*: any*/),
                          (v57/*: any*/),
                          (v40/*: any*/)
                        ],
                        "type": "JiraSingleSelectUserPickerField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v37/*: any*/),
                          {
                            "args": (v41/*: any*/),
                            "concreteType": "JiraVersionConnection",
                            "kind": "LinkedField",
                            "name": "selectedVersionsConnection",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "JiraVersionEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "concreteType": "JiraVersion",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": (v56/*: any*/)
                                  }
                                ]
                              }
                            ],
                            "storageKey": "selectedVersionsConnection(first:1000)"
                          }
                        ],
                        "type": "JiraMultipleVersionPickerField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "JiraRichText",
                            "kind": "LinkedField",
                            "name": "richText",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "JiraADF",
                                "kind": "LinkedField",
                                "name": "adfValue",
                                "plural": false,
                                "selections": [
                                  {
                                    "condition": "isDensityFull",
                                    "kind": "Condition",
                                    "passingValue": false,
                                    "selections": [
                                      {
                                        "concreteType": "JiraAdfToConvertedPlainText",
                                        "kind": "LinkedField",
                                        "name": "convertedPlainText",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "kind": "ScalarField",
                                            "name": "plainText"
                                          }
                                        ]
                                      }
                                    ]
                                  },
                                  {
                                    "condition": "isDensityFull",
                                    "kind": "Condition",
                                    "passingValue": true,
                                    "selections": [
                                      {
                                        "kind": "ScalarField",
                                        "name": "json"
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ],
                        "type": "JiraRichTextField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "JiraServiceManagementRequestType",
                            "kind": "LinkedField",
                            "name": "requestType",
                            "plural": false,
                            "selections": [
                              (v37/*: any*/),
                              (v62/*: any*/),
                              (v26/*: any*/)
                            ]
                          }
                        ],
                        "type": "JiraServiceManagementRequestTypeField"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "first",
                                "value": 1
                              }
                            ],
                            "concreteType": "JiraIssueLinkConnection",
                            "kind": "LinkedField",
                            "name": "issueLinkConnection",
                            "plural": false,
                            "selections": [
                              (v47/*: any*/),
                              {
                                "concreteType": "JiraIssueLinkEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "concreteType": "JiraIssueLink",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "concreteType": "JiraIssue",
                                        "kind": "LinkedField",
                                        "name": "issue",
                                        "plural": false,
                                        "selections": [
                                          (v64/*: any*/),
                                          (v27/*: any*/),
                                          {
                                            "args": [
                                              {
                                                "kind": "Literal",
                                                "name": "ids",
                                                "value": [
                                                  "statusCategory"
                                                ]
                                              }
                                            ],
                                            "concreteType": "JiraIssueFieldConnection",
                                            "kind": "LinkedField",
                                            "name": "fieldsById",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "concreteType": "JiraIssueFieldEdge",
                                                "kind": "LinkedField",
                                                "name": "edges",
                                                "plural": true,
                                                "selections": [
                                                  {
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                      (v24/*: any*/),
                                                      {
                                                        "kind": "InlineFragment",
                                                        "selections": [
                                                          (v31/*: any*/)
                                                        ],
                                                        "type": "JiraStatusCategoryField"
                                                      },
                                                      (v26/*: any*/)
                                                    ]
                                                  }
                                                ]
                                              }
                                            ],
                                            "storageKey": "fieldsById(ids:[\"statusCategory\"])"
                                          },
                                          (v26/*: any*/)
                                        ]
                                      },
                                      {
                                        "kind": "ScalarField",
                                        "name": "relationName"
                                      },
                                      (v26/*: any*/)
                                    ]
                                  }
                                ]
                              }
                            ],
                            "storageKey": "issueLinkConnection(first:1)"
                          }
                        ],
                        "type": "JiraIssueLinkField"
                      },
                      {
                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider",
                        "kind": "Condition",
                        "passingValue": true,
                        "selections": [
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "name": "isStoryPointField"
                              },
                              (v65/*: any*/),
                              (v37/*: any*/),
                              {
                                "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider",
                                "kind": "Condition",
                                "passingValue": false,
                                "selections": [
                                  (v65/*: any*/)
                                ]
                              },
                              {
                                "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider",
                                "kind": "Condition",
                                "passingValue": true,
                                "selections": [
                                  (v65/*: any*/),
                                  (v37/*: any*/),
                                  (v40/*: any*/)
                                ]
                              },
                              (v40/*: any*/)
                            ],
                            "type": "JiraNumberField"
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
],
v67 = {
  "args": [
    {
      "kind": "Variable",
      "name": "filterByProjectKeys",
      "variableName": "projectKeys"
    }
  ],
  "kind": "ScalarField",
  "name": "hasChildren"
},
v68 = {
  "kind": "ClientExtension",
  "selections": [
    (v34/*: any*/)
  ]
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/),
      (v12/*: any*/),
      (v13/*: any*/),
      (v14/*: any*/),
      (v15/*: any*/),
      (v16/*: any*/),
      (v17/*: any*/),
      (v18/*: any*/),
      (v19/*: any*/),
      (v20/*: any*/),
      (v21/*: any*/)
    ],
    "kind": "Fragment",
    "name": "tableBody_issueTableHierarchy_childIssuesPaginationQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "isDensityFull",
            "variableName": "isDensityFull"
          },
          {
            "kind": "Variable",
            "name": "isInlineEditingEnabled",
            "variableName": "isInlineEditingEnabled"
          },
          {
            "kind": "Variable",
            "name": "isReparentingEnabled",
            "variableName": "isReparentingEnabled"
          },
          (v22/*: any*/),
          {
            "kind": "Variable",
            "name": "projectKeys",
            "variableName": "projectKeys"
          },
          {
            "kind": "Variable",
            "name": "shouldQueryHasChildren",
            "variableName": "shouldQueryHasChildren"
          }
        ],
        "kind": "FragmentSpread",
        "name": "childRowsPagination_issueTableHierarchy_query"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/),
      (v12/*: any*/),
      (v13/*: any*/),
      (v14/*: any*/),
      (v15/*: any*/),
      (v16/*: any*/),
      (v17/*: any*/),
      (v18/*: any*/),
      (v19/*: any*/),
      (v20/*: any*/),
      (v21/*: any*/),
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone2experimentrelayprovider"
      }
    ],
    "kind": "Operation",
    "name": "tableBody_issueTableHierarchy_childIssuesPaginationQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v23/*: any*/),
            "concreteType": "JiraIssueConnection",
            "kind": "LinkedField",
            "name": "issueSearch",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraIssueEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  (v24/*: any*/),
                  (v25/*: any*/),
                  {
                    "concreteType": "JiraIssue",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v24/*: any*/),
                      (v26/*: any*/),
                      (v27/*: any*/),
                      (v28/*: any*/),
                      {
                        "kind": "ScalarField",
                        "name": "isResolved"
                      },
                      {
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "ids",
                            "value": [
                              "status"
                            ]
                          }
                        ],
                        "concreteType": "JiraIssueFieldConnection",
                        "kind": "LinkedField",
                        "name": "fieldsById",
                        "plural": false,
                        "selections": [
                          {
                            "concreteType": "JiraIssueFieldEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v24/*: any*/),
                                  (v32/*: any*/),
                                  (v26/*: any*/)
                                ]
                              }
                            ]
                          }
                        ],
                        "storageKey": "fieldsById(ids:[\"status\"])"
                      },
                      {
                        "concreteType": "JiraIssueTypeField",
                        "kind": "LinkedField",
                        "name": "issueTypeField",
                        "plural": false,
                        "selections": [
                          {
                            "concreteType": "JiraIssueType",
                            "kind": "LinkedField",
                            "name": "issueType",
                            "plural": false,
                            "selections": [
                              (v33/*: any*/),
                              (v26/*: any*/)
                            ]
                          },
                          (v26/*: any*/)
                        ]
                      },
                      {
                        "kind": "ClientExtension",
                        "selections": [
                          (v34/*: any*/),
                          {
                            "kind": "ScalarField",
                            "name": "isHighlightedIssueRow"
                          }
                        ]
                      },
                      {
                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider",
                        "kind": "Condition",
                        "passingValue": false,
                        "selections": [
                          {
                            "condition": "shouldQueryFieldSetsById",
                            "kind": "Condition",
                            "passingValue": true,
                            "selections": [
                              {
                                "args": [
                                  {
                                    "kind": "Variable",
                                    "name": "fieldSetIds",
                                    "variableName": "fieldSetIds"
                                  },
                                  (v35/*: any*/)
                                ],
                                "concreteType": "JiraIssueFieldSetConnection",
                                "kind": "LinkedField",
                                "name": "fieldSetsById",
                                "plural": false,
                                "selections": (v66/*: any*/)
                              }
                            ]
                          },
                          {
                            "condition": "shouldQueryFieldSetsById",
                            "kind": "Condition",
                            "passingValue": false,
                            "selections": [
                              {
                                "args": [
                                  {
                                    "kind": "Variable",
                                    "name": "context",
                                    "variableName": "fieldSetsContext"
                                  },
                                  {
                                    "kind": "Variable",
                                    "name": "filterId",
                                    "variableName": "filterId"
                                  },
                                  (v35/*: any*/),
                                  {
                                    "kind": "Variable",
                                    "name": "namespace",
                                    "variableName": "namespace"
                                  },
                                  {
                                    "kind": "Variable",
                                    "name": "viewId",
                                    "variableName": "viewId"
                                  }
                                ],
                                "concreteType": "JiraIssueFieldSetConnection",
                                "kind": "LinkedField",
                                "name": "fieldSetsForIssueSearchView",
                                "plural": false,
                                "selections": (v66/*: any*/)
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "condition": "isDensityFull",
                        "kind": "Condition",
                        "passingValue": true,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "canBeExported"
                          }
                        ]
                      },
                      {
                        "condition": "isReparentingEnabled",
                        "kind": "Condition",
                        "passingValue": true,
                        "selections": [
                          (v58/*: any*/),
                          {
                            "concreteType": "JiraIssueTypeField",
                            "kind": "LinkedField",
                            "name": "issueTypeField",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "JiraIssueType",
                                "kind": "LinkedField",
                                "name": "issueType",
                                "plural": false,
                                "selections": [
                                  (v59/*: any*/)
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "condition": "shouldQueryHasChildren",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                      (v67/*: any*/),
                      {
                        "args": [
                          (v22/*: any*/)
                        ],
                        "kind": "ScalarField",
                        "name": "canHaveChildIssues"
                      }
                    ]
                  },
                  (v68/*: any*/),
                  {
                    "condition": "isReparentingEnabled",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                      (v67/*: any*/)
                    ]
                  },
                  {
                    "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                      {
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "first",
                            "value": 500
                          }
                        ],
                        "concreteType": "JiraIssueFieldSetConnection",
                        "kind": "LinkedField",
                        "name": "fieldSets",
                        "plural": false,
                        "selections": (v66/*: any*/),
                        "storageKey": "fieldSets(first:500)"
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "name": "totalIssueSearchResultCount"
              },
              {
                "kind": "ScalarField",
                "name": "isCappingIssueSearchResult"
              },
              {
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  (v54/*: any*/),
                  (v55/*: any*/),
                  {
                    "kind": "ScalarField",
                    "name": "hasPreviousPage"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "startCursor"
                  }
                ]
              },
              (v68/*: any*/)
            ]
          },
          {
            "args": (v23/*: any*/),
            "filters": [
              "cloudId",
              "issueSearchInput",
              "options",
              "fieldSetsInput",
              "viewConfigInput"
            ],
            "handle": "connection",
            "key": "hierarchy_nativeIssueTable__issueSearch",
            "kind": "LinkedHandle",
            "name": "issueSearch"
          }
        ]
      }
    ]
  },
  "params": {
    "id": "76b36d4f04b088c75341e092893164edb9a67160b7d70f7728305d97a183b376",
    "metadata": {},
    "name": "tableBody_issueTableHierarchy_childIssuesPaginationQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider": isJscInfiniteScrollEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider": isJscM2Enabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider": isNikeChinStoryPointsInlineEditEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider": isJscInlineEditRefactorEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone2experimentrelayprovider": includeSingleSelectColorField_provider
    }
  }
};
})();

(node as any).hash = "99eb249d45b9191b7ffc164979f8522f";

export default node;
