/**
 * @generated SignedSource<<af30466501d29bf9367df09283f83219>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type confluencePages_issueFieldConfluencePagesInlineEditFull_ConfluencePagesInlineEditView_fragmentRef$data = {
  readonly fieldConfig: {
    readonly isEditable: boolean | null | undefined;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"confluencePages_issueFieldConfluencePagesInlineEditFull_ConfluencePagesInlineEditViewWithIsEditable_fragmentRef">;
  readonly " $fragmentType": "confluencePages_issueFieldConfluencePagesInlineEditFull_ConfluencePagesInlineEditView_fragmentRef";
};
export type confluencePages_issueFieldConfluencePagesInlineEditFull_ConfluencePagesInlineEditView_fragmentRef$key = {
  readonly " $data"?: confluencePages_issueFieldConfluencePagesInlineEditFull_ConfluencePagesInlineEditView_fragmentRef$data;
  readonly " $fragmentSpreads": FragmentRefs<"confluencePages_issueFieldConfluencePagesInlineEditFull_ConfluencePagesInlineEditView_fragmentRef">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "confluencePages_issueFieldConfluencePagesInlineEditFull_ConfluencePagesInlineEditView_fragmentRef",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "confluencePages_issueFieldConfluencePagesInlineEditFull_ConfluencePagesInlineEditViewWithIsEditable_fragmentRef"
    },
    {
      "concreteType": "JiraFieldConfig",
      "kind": "LinkedField",
      "name": "fieldConfig",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "isEditable"
        }
      ]
    }
  ],
  "type": "JiraConfluenceRemoteIssueLinksField"
};

(node as any).hash = "b82ba55e135c40c22b29327df36827d2";

export default node;
