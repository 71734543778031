import React, { useCallback, useMemo, useState } from 'react';
import { useFragment, graphql, useMutation } from 'react-relay';
import type { OnSubmitCallbacks } from '@atlassian/jira-issue-field-inline-edit-actions/src/controllers/types.tsx';
import { useFieldInlineEditActions } from '@atlassian/jira-issue-field-inline-edit-actions/src/controllers/index.tsx';
import type { ValidationFieldProps } from '@atlassian/jira-issue-field-inline-edit-lite/src/ui/field-inline-edit-lite/types.tsx';
import { FieldInlineEditLiteWithEntryPoint } from '@atlassian/jira-issue-field-inline-edit-lite/src/ui/index.tsx';
import {
	isRecentlyCreatedTeam,
	type NullableTeamOption,
	type TeamEditViewProps,
} from '@atlassian/jira-issue-field-team-editview-full/src/ui/team/types.tsx';
import TeamEditViewEntryPoint from '@atlassian/jira-issue-field-team-editview-full/src/entrypoint.tsx';
import { TeamReadView } from '@atlassian/jira-issue-field-team-readview-full/src/ui/team/index.tsx';
import { useInlineEditFieldInjections } from '@atlassian/jira-issue-field-injections/src/controllers/inline-edit-injections-context/index.tsx';
import type { team_issueFieldTeam_TeamField_Mutation as TeamMutation } from '@atlassian/jira-relay/src/__generated__/team_issueFieldTeam_TeamField_Mutation.graphql';
import type { team_issueFieldTeamInlineEditFull_TeamInlineEditView_fragmentRef$key as TeamFragment } from '@atlassian/jira-relay/src/__generated__/team_issueFieldTeamInlineEditFull_TeamInlineEditView_fragmentRef.graphql';
import type { team_issueFieldTeamInlineEditFull_TeamInlineEditViewWithIsEditable_fragmentRef$key as TeamWithIsEditableFragment } from '@atlassian/jira-relay/src/__generated__/team_issueFieldTeamInlineEditFull_TeamInlineEditViewWithIsEditable_fragmentRef.graphql';
import { useMutationWithRetriesCommit } from '@atlassian/jira-issue-use-commit-with-retries/src/services/index.tsx';
import type { TeamInlineEditViewProps, TeamInlineEditViewWithIsEditableProps } from './types.tsx';

/** Determine if the updated edit view value is equal to our original value before submitting a mutation. */
const isEqualOption = (a: NullableTeamOption | null, b: NullableTeamOption | null) =>
	a?.id === b?.id;
/**
 * Inline edit will handle the switching behaviour between the 'readView' and 'editView' components. This variant allows
 * consumers to define their own value to determine whether the field is editable.
 *
 * In most cases consumers should use `TeamInlineEditView` which will enforce that the user has permission to
 * edit the field within the issue view. However, this component can be used for experiences that have differing
 * permissions or want finer control for how this data is retrieved, e.g. lazy loading editability.
 *
 * @param props [TeamInlineEditViewWithIsEditableProps](./types.tsx)
 */
export const TeamInlineEditViewWithIsEditable = ({
	attributes,
	editViewPopup,
	editViewPopupAlignBlock,
	fragmentRef,
	isEditable,
	isEditing: startWithEditViewOpen = false,
	menuPosition,
	menuPortalTarget,
	onSubmit,
	onSubmitFailed,
	onSubmitSucceeded,
	readViewFitContainerHeight,
	spacing = 'compact',
	sessionId,
	maxLines,
}: TeamInlineEditViewWithIsEditableProps) => {
	// #region Relay
	const data = useFragment<TeamWithIsEditableFragment>(
		graphql`
			fragment team_issueFieldTeamInlineEditFull_TeamInlineEditViewWithIsEditable_fragmentRef on JiraTeamViewField {
				id
				name
				fieldId
				type
				...team_issueFieldTeamReadviewFull_TeamReadView
				selectedTeam {
					jiraSuppliedId
					jiraSuppliedName
				}
			}
		`,
		fragmentRef,
	);

	// Team will not always be available in Jira when is recently created,
	// at least not right away, due to eventual consistency. We are retrying
	// to give time to Jira to receive the newly team and accepts the given
	// team ARI as a valid team
	const retryCommit = useMutationWithRetriesCommit<TeamMutation>(
		useMemo(
			() => ({
				retries: 3,
				isValidOnCompleted: (response) => Boolean(response.jira?.updateTeamField?.success),
			}),
			[],
		),
	);
	const [commit] = useMutation<TeamMutation>(
		graphql`
			mutation team_issueFieldTeam_TeamField_Mutation($input: JiraUpdateTeamFieldInput!)
			@raw_response_type {
				jira @optIn(to: ["JiraIssueFieldMutations"]) {
					updateTeamField(input: $input) {
						success
						errors {
							message
						}
						field {
							...team_issueFieldTeamInlineEditFull_TeamInlineEditViewWithIsEditable_fragmentRef
						}
					}
				}
			}
		`,
		retryCommit,
	);
	// #endregion
	// #region Common state
	const { id: uniqueFieldId, name, selectedTeam, fieldId, type: fieldType } = data;
	const initialValue: NullableTeamOption = useMemo(() => {
		if (!selectedTeam) return null;
		return {
			id: selectedTeam.jiraSuppliedId,
			name: selectedTeam.jiraSuppliedName ?? '',
		};
	}, [selectedTeam]);
	const [updatedValue, setUpdatedValue] = useState<NullableTeamOption>(initialValue);
	const { overriding } = useInlineEditFieldInjections();
	const { overrideLabel, overrideIsEditable } = overriding;
	const fieldName = useMemo(() => overrideLabel(name), [overrideLabel, name]);
	const isFieldEditable = useMemo(
		() => overrideIsEditable(isEditable),
		[overrideIsEditable, isEditable],
	);
	// #endregion Common state
	// #region Actions

	const handleSubmit = useCallback(
		(value: NullableTeamOption, { onFail, onSuccess }: OnSubmitCallbacks) => {
			onSubmit?.(value);
			const id = isRecentlyCreatedTeam(value) ? value.teamAri : value?.id ?? null;
			commit({
				variables: {
					input: {
						id: uniqueFieldId,
						operation: {
							operation: 'SET',
							id,
						},
					},
				},
				onCompleted(response) {
					if (response.jira?.updateTeamField?.success) {
						onSuccess();
					} else {
						onFail();
					}
				},
				onError(error) {
					onFail(error);
				},
				optimisticResponse: {
					jira: {
						updateTeamField: {
							success: true,
							errors: null,
							field: {
								id: uniqueFieldId,
								name: fieldName,
								fieldId,
								type: fieldType,
								selectedTeam: value && {
									jiraSuppliedId: id ?? '',
									jiraSuppliedName: value?.name ?? '',
								},
							},
						},
					},
				},
			});
		},
		[commit, fieldId, fieldName, fieldType, onSubmit, uniqueFieldId],
	);
	const {
		invalidMessage,
		isEditing,
		hasServerValidationError,
		handleCancel,
		handleEdit,
		handleCopy,
		handleCut,
		handlePaste,
		handleConfirm,
		handleChangeAndConfirm,
	} = useFieldInlineEditActions({
		attributes,
		fieldId,
		fieldName,
		fieldType,
		initialValue,
		isValueEqual: isEqualOption,
		onSubmit: handleSubmit,
		onSubmitFailed,
		onSubmitSucceeded,
		onUpdateValue: setUpdatedValue,
		startWithEditViewOpen,
		updatedValue,
		clipboardComponentType: 'team',
	});
	// #endregion Actions
	// #region Read view
	const renderReadView = () => (
		<TeamReadView
			fragmentRef={data}
			maxLines={maxLines}
			triggerLinkType={isFieldEditable ? 'none' : 'link'}
		/>
	);
	// #endregion
	// #region Edit view
	const getEditViewProps = (fieldProps: ValidationFieldProps): TeamEditViewProps => ({
		...fieldProps,
		autoFocus: true,
		fieldId: uniqueFieldId,
		fieldName,
		value: updatedValue,
		menuPosition,
		menuPortalTarget,
		onChange: handleChangeAndConfirm,
		spacing,
		sessionId,
	});
	// #endregion
	return (
		<FieldInlineEditLiteWithEntryPoint
			editViewPopup={editViewPopup}
			editViewPopupAlignBlock={editViewPopupAlignBlock}
			editViewEntryPoint={TeamEditViewEntryPoint}
			editViewEntryPointParams={{}}
			getEditViewProps={getEditViewProps}
			fieldName={fieldName}
			hasUnsubmittedChanges={hasServerValidationError}
			invalidMessage={invalidMessage}
			isEditing={isEditing}
			isEditable={isFieldEditable}
			onCancel={handleCancel}
			onConfirm={handleConfirm}
			onEdit={handleEdit}
			onCopy={handleCopy}
			onCut={handleCut}
			onPaste={handlePaste}
			readViewFitContainerHeight={readViewFitContainerHeight}
			readView={renderReadView}
			hideActionButtons
		/>
	);
};

/**
 * Inline edit will handle the switching behaviour between the 'readView' and 'editView' components.
 *
 * @param props [TeamInlineEditViewProps](./types.tsx)
 */
export const TeamInlineEditView = ({ fragmentRef, ...props }: TeamInlineEditViewProps) => {
	// #region Relay
	const data = useFragment<TeamFragment>(
		graphql`
			fragment team_issueFieldTeamInlineEditFull_TeamInlineEditView_fragmentRef on JiraTeamViewField {
				...team_issueFieldTeamInlineEditFull_TeamInlineEditViewWithIsEditable_fragmentRef
				fieldConfig {
					isEditable
				}
			}
		`,
		fragmentRef,
	);
	// #endregion
	return (
		<TeamInlineEditViewWithIsEditable
			{...props}
			fragmentRef={data}
			isEditable={data.fieldConfig?.isEditable ?? false}
		/>
	);
};
