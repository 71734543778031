import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import CalendarIcon from '@atlaskit/icon/core/migration/calendar';
import ClockIcon from '@atlaskit/icon/core/clock';
import WarningOutlineIcon from '@atlaskit/icon-lab/core/warning-outline';
import Tooltip from '@atlaskit/tooltip';
import { token } from '@atlaskit/tokens';
import { useLocale } from '@atlassian/jira-tenant-context-controller/src/components/locale/index.tsx';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import { messages } from './messages.tsx';

type ColoredDueDateProps = {
	dateString: string;
	pastDue: boolean;
	dueToday: boolean;
	warningIconTestId: string;
	clockIconTestId: string;
	calendarIconTestId: string;
	tooltipTestId?: string;
	isShortFormat?: boolean;
};

export const ColoredDueDate = ({
	dateString,
	pastDue,
	dueToday,
	warningIconTestId,
	clockIconTestId,
	calendarIconTestId,
	tooltipTestId,
	isShortFormat = false,
}: ColoredDueDateProps) => {
	let locale = useLocale();
	if (locale === undefined) {
		locale = 'en_US';
	}
	const localeString = locale.replace('_', '-');
	const formatter = new Intl.DateTimeFormat(localeString, {
		year: 'numeric',
		month: 'short',
		day: 'numeric',
		timeZone: 'UTC',
		timeZoneName: 'short',
	});
	const parts = formatter.formatToParts(new Date(dateString));

	const day = parts.find((part) => part.type === 'day')?.value;
	const month = parts.find((part) => part.type === 'month')?.value;
	const year = parts.find((part) => part.type === 'year')?.value;
	const timezone = parts.find((part) => part.type === 'timeZoneName')?.value;

	// Use short format (without year) if isShortFormat is true
	const displayDate = isShortFormat ? `${day} ${month}` : `${day} ${month} ${year}`;
	const tooltipDate = `${day} ${month} ${year}, ${timezone}`;

	const dueMessage = pastDue ? (
		<FormattedMessage {...messages.overdue} values={{ date: tooltipDate }} />
	) : (
		<FormattedMessage {...messages.due} values={{ date: tooltipDate }} />
	);

	const { formatMessage } = useIntl();
	const iconLabel = pastDue
		? formatMessage(messages.overdue, { date: tooltipDate })
		: formatMessage(messages.due, { date: tooltipDate });

	return (
		<Box
			xcss={[
				styles.dateTag,
				styles.flexContainer,
				pastDue ? styles.overdue : undefined,
				dueToday ? styles.dueToday : undefined,
				!pastDue && !dueToday ? styles.normalDate : undefined,
			]}
			testId="coloured-due-date.ui.colored-due-date-container"
			tabIndex={0}
			role="button"
			aria-label={iconLabel}
		>
			{pastDue && (
				<Tooltip content={dueMessage} position="top" testId={tooltipTestId}>
					<Box xcss={styles.dueDateIcon} testId="coloured-due-date.ui.tooltip-container">
						<WarningOutlineIcon
							LEGACY_size="medium"
							label={iconLabel}
							color={token('color.icon.danger')}
							testId={warningIconTestId}
						/>
					</Box>
				</Tooltip>
			)}
			{dueToday && (
				<Tooltip content={dueMessage} position="top" testId={tooltipTestId}>
					<Box xcss={styles.dueDateIcon} testId="coloured-due-date.ui.tooltip-container">
						<ClockIcon
							LEGACY_size="medium"
							label={iconLabel}
							color={token('color.icon.warning')}
							testId={clockIconTestId}
						/>
					</Box>
				</Tooltip>
			)}
			{!dueToday && !pastDue && (
				<Tooltip content={dueMessage} position="top" testId={tooltipTestId}>
					<Box xcss={styles.dueDateIcon} testId="coloured-due-date.ui.tooltip-container">
						<CalendarIcon
							label={iconLabel}
							color={token('color.icon.subtle')}
							LEGACY_size="medium"
							testId={calendarIconTestId}
						/>
					</Box>
				</Tooltip>
			)}
			<span aria-hidden="true">{displayDate}</span>
		</Box>
	);
};

const styles = {
	dateTag: xcss({
		paddingLeft: 'space.075',
		paddingRight: 'space.075',
		paddingTop: 'space.025',
		paddingBottom: 'space.025',
		borderStyle: 'solid',
		borderRadius: 'border.radius.100',
		borderWidth: 'border.width',
		color: 'color.text',
		cursor: 'pointer',
	}),
	flexContainer: xcss({
		display: 'flex',
		alignItems: 'center',
	}),
	overdue: xcss({
		color: 'color.text.danger',
		borderColor: 'color.border.danger',
	}),
	dueToday: xcss({
		color: 'color.text.warning',
		borderColor: 'color.border.warning',
	}),
	normalDate: xcss({
		color: 'color.text',
		borderColor: 'color.border',
	}),
	dueDateIcon: xcss({
		marginRight: 'space.050',
		display: 'flex',
		alignItems: 'center',
		height: '100%',
	}),
};
