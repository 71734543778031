/**
 * @generated SignedSource<<5efe72fb049955631221e390b2167de4>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type goals_issueFieldGoalsReadviewFull_GoalsReadView$data = {
  readonly selectedGoals: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly key: string | null | undefined;
      } | null | undefined;
    } | null | undefined> | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"popupContent_issueFieldGoalsReadviewFull">;
  } | null | undefined;
  readonly " $fragmentType": "goals_issueFieldGoalsReadviewFull_GoalsReadView";
};
export type goals_issueFieldGoalsReadviewFull_GoalsReadView$key = {
  readonly " $data"?: goals_issueFieldGoalsReadviewFull_GoalsReadView$data;
  readonly " $fragmentSpreads": FragmentRefs<"goals_issueFieldGoalsReadviewFull_GoalsReadView">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "selectedGoals"
        ]
      }
    ]
  },
  "name": "goals_issueFieldGoalsReadviewFull_GoalsReadView",
  "selections": [
    {
      "alias": "selectedGoals",
      "concreteType": "JiraGoalConnection",
      "kind": "LinkedField",
      "name": "__Goals_selectedGoals_connection",
      "plural": false,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "popupContent_issueFieldGoalsReadviewFull"
        },
        {
          "concreteType": "JiraGoalEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraGoal",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "id"
                },
                {
                  "kind": "ScalarField",
                  "name": "key"
                },
                {
                  "kind": "ScalarField",
                  "name": "__typename"
                }
              ]
            },
            {
              "kind": "ScalarField",
              "name": "cursor"
            }
          ]
        },
        {
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "endCursor"
            },
            {
              "kind": "ScalarField",
              "name": "hasNextPage"
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraGoalsField"
};

(node as any).hash = "9b4eb6d6057f9552f081fab077f7c983";

export default node;
