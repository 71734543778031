import React, { useRef } from 'react';
import { graphql, useFragment } from 'react-relay';
import { v4 as uuid } from 'uuid';
import type { team_nativeIssueTable_TeamCell$key } from '@atlassian/jira-relay/src/__generated__/team_nativeIssueTable_TeamCell.graphql';
import { TeamInlineEditViewWithIsEditable } from '@atlassian/jira-issue-field-team-inline-edit-full/src/ui/team/index.tsx';
import { INLINE_EDIT_ANALYTICS_ATTRIBUTES } from '../common/inline-edit-analytics-attributes.tsx';
import { useLazyLoadEditability } from '../../../../services/lazy-editability-loader/index.tsx';
import {
	useIsInlineEditingExtendedFieldSupportEnabled,
	useIsSingleLineRowHeightEnabled,
} from '../../../../controllers/features/selectors.tsx';

export type Props = {
	fieldRef: team_nativeIssueTable_TeamCell$key;
};

export const TeamCell = ({ fieldRef }: Props) => {
	const sessionId = useRef(uuid());

	const data = useFragment<team_nativeIssueTable_TeamCell$key>(
		graphql`
			fragment team_nativeIssueTable_TeamCell on JiraTeamViewField
			@argumentDefinitions(isInlineEditingEnabled: { type: "Boolean!" }) {
				id
				lazyIsEditableInIssueView @include(if: $isInlineEditingEnabled)
				...team_issueFieldTeamInlineEditFull_TeamInlineEditViewWithIsEditable_fragmentRef
			}
		`,
		fieldRef,
	);

	const isInlineEditingExtendedFieldSupportEnabled =
		useIsInlineEditingExtendedFieldSupportEnabled();
	// If the property does not exist in fragment data then we know it has been conditionally excluded because inline
	// editing is disabled. In this case we explicitly set isEditable to false to bypass the lazy editability query.
	const isEditable =
		'lazyIsEditableInIssueView' in data && isInlineEditingExtendedFieldSupportEnabled
			? data.lazyIsEditableInIssueView
			: false;
	useLazyLoadEditability(data.id, isEditable);

	const isSingleLineRowHeightEnabled = useIsSingleLineRowHeightEnabled();

	return (
		<TeamInlineEditViewWithIsEditable
			attributes={INLINE_EDIT_ANALYTICS_ATTRIBUTES}
			spacing="default"
			editViewPopup
			editViewPopupAlignBlock="center"
			fragmentRef={data}
			isEditable={isEditable ?? false}
			menuPosition="fixed"
			readViewFitContainerHeight
			sessionId={sessionId.current}
			maxLines={isSingleLineRowHeightEnabled ? 1 : 2}
		/>
	);
};
