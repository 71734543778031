import React from 'react';
import { graphql, useFragment } from 'react-relay';
import { GoalsReadView } from '@atlassian/jira-issue-field-goals-readview-full/src/ui/goals/index.tsx';
import type { goals_nativeIssueTable_GoalsCell$key } from '@atlassian/jira-relay/src/__generated__/goals_nativeIssueTable_GoalsCell.graphql';

export type Props = {
	fieldRef: goals_nativeIssueTable_GoalsCell$key;
};

export const GoalsCell = ({ fieldRef }: Props) => {
	const data = useFragment<goals_nativeIssueTable_GoalsCell$key>(
		graphql`
			fragment goals_nativeIssueTable_GoalsCell on JiraGoalsField {
				...goals_issueFieldGoalsReadviewFull_GoalsReadView
			}
		`,
		fieldRef,
	);

	return <GoalsReadView fragmentRef={data} />;
};
